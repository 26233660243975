.dd-image {

  &--small {
    img {
      display: block;
      width: 50%;
      margin: auto; } }

  &__caption {
    font-size: 16px;
    padding-bottom: 10px; } }

