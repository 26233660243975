.dd-breadcrumb {
  padding-top: 20px;

  ul {
    list-style: none;
    padding-left: 0px; }

  li {
    display: inline-block;
    font-size: 12px;
    padding-right: 20px;
    color: $blue;
    position: relative;

    a {
      color: $gray-600;

      &:after {
        content: '';
        width: 5px;
        height: 5px;
        border-top: 1px solid $gray-600;
        border-right: 1px solid $gray-600;
        display: block;
        position: relative;
        transform: rotate(45deg);
        position: absolute;
        right: 7px;
        top: 7px; } } } }
