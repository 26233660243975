.navbar {
  min-width: 320px;

  &.sticky-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1020; }

  &__logo {
    @include mq($until: md) {
      height: 30px; } } }
