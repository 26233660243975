.dd-product-slider {

  img {
    min-width: 100%; }

  .slick-dots {
    position: absolute;
    bottom: 0;

    @include mq($from: lg) {
      bottom: 30px; }

    li button {
      &:before {
        color: $primary;
        font-size: 12px; } }

    .slick-active {
      li button {
        &:before {
          color: $secondary; } } } }

  button {
    height: 30px;
    width: 30px;
    &.slick-prev, &.slick-next {
      &::before {
        content: '';
        display: block;
        height: 30px;
        width: 30px;
        border-top: 3px solid $blue;
        border-right: 3px solid $blue;
        transform: rotate(-45deg);
        position: absolute; } } }

  button.slick-prev {
    transform: rotate(-90deg);
    left: -45px;

    @include mq($until: sm) {
      left: -5px;
      z-index: 1; } }

  button.slick-next {
    transform: rotate(90deg);
    right: -45px;

    @include mq($until: sm) {
      right: -5px;
      z-index: 1; } } }

.dd-subsite-content {
  button.slick-arrow {
    &.slick-prev, &.slick-next {
      &::before {
        border-top-color: white;
        border-right-color: white; } } } }
