.dd-footer {
  color: $white;
  background-color: #82898e; // Röchling grau?
  margin-bottom: 0px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3.5rem;

  // subsite.sass
  position: relative;
  z-index: 1018;

  @include mq($until: md) {
    padding-left: 0; }

  &__sitemap {
    margin-bottom: 1rem;

    &-col {
      margin-bottom: 1.5rem; }

    &-category {
      @include f-s;
      font-size: 18px; }

    &-list {
      list-style: none;
      display: initial;
      padding-left: 0;

      li > a {
        color: $gray-200;
        text-decoration: none;

        &:hover {
          color: $gray-800; } } } }

  .dd-divider {
    width: 100%;
    height: 1px;
    background-color: $gray-200;
    display: block;
    margin-bottom: 2rem; }

  &__cta {
    margin-bottom: 1.5rem;

    &-title {
      @include f-s;
      font-size: 18px;
      margin-bottom: 1.5rem; }

    &-social {
      ul {
        padding: 0;
        display: inline-flex;
        list-style: none;

        li {
          margin-right: 1.5rem; }

        .icon {
          height: 25px;
          width: 25px;

          svg use {
            fill: $white; }

          &:hover {
            cursor: pointer;

            svg use {
              fill: $gray-800; } } } } }


    &-buttons {
      button.btn {
        background: $white;
        color: #686C6F;
        margin-right: 1rem;

        &:hover {
          color: $gray-800; } } } }

  &__top {
    position: absolute;
    right: 15px;
    height: 60px;
    width: 30px;

    &-icon {
      content: '';
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background: $white;
      display: block;

      &::after {
        content: '';
        display: block;
        height: 11px;
        width: 11px;
        border-top: 3px solid rgba($gray-600, 0.7);
        border-right: 3px solid rgba($gray-600, 0.7);
        transform: rotate(-45deg);
        position: absolute;
        right: 9px;
        top: 12px; } }

    &-text {
      font-size: 10pt;
      text-align: center;
      padding-top: 5px; } }

  &__menu {
    font-size: 12px;
    color: $white;

    ul {
      list-style: none;
      display: flex;
      flex-direction: row-reverse;

      li>a {
        margin-left: 2rem;
        color: $white;

        &:hover {
          color: $gray-800;
          text-decoration: none; } } } } }


// kopiert von layout.sass
footer {
  .navbar {
    min-height: 70px; }


  ul.navbar-nav > li.nav-item > a.nav-link {
    margin-left: 1rem;
    color: white;

    &:hover,
    &:focus {
      color: $roe-vibrant-cyan; } } }
