.dd-slider {
  &.slick-slider {
    &.slick-dotted {
      margin-bottom: 0; } }

  // FIXME - DELETE NACH GO LIVE
  .slick-track {
    .slick-slide {
      &:nth-child(odd) {
        * {
          color: $blue; } } } }

  .slick-dots {
    bottom: 40px;
    @include mq($until: md) {
      bottom: 20px; }


    li {
      margin: 0;
      color: $gray-400 !important;

      button:hover:before,
      button:focus:before {
        color: $secondary; }

      &.slick-active button:hover:before,
      &.slick-active button:focus:before {
        color: $primary; } } } }



.dd-cards-press {
  .slick-dots {
    button {
      &:before {
        color: $gray-400; } } } }


