@charset "UTF-8";

@mixin dd-font-face($font-id, $font-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family-sans-serif;
    src: url('fonts/#{$font-path}.eot');
    src: local('☺︎'), url('fonts/#{$font-path}.eot?#iefix') format('embedded-opentype'), url('fonts/#{$font-path}.woff2') format('woff2'), url('fonts/#{$font-path}.woff') format('woff'), url('fonts/#{$font-path}.ttf') format('truetype');
    font-weight: #{$font-weight};
    font-style: #{$font-style}; }

  .f-#{$font-id} {
    font-family: $font-id; } }


// +dd-font-face(l, EuclidSquare-Light-WebM, 300, normal)
// +dd-font-face(li, EuclidSquare-LightItalic-WebM, 300, italic)
@include dd-font-face(r, EuclidSquare-Regular-WebM, normal, normal);
// +dd-font-face(ri, EuclidSquare-RegularItalic-WebM, normal, italic)
@include dd-font-face(m, EuclidSquare-Medium-WebM, 500, normal);
// +dd-font-face(mi, EuclidSquare-MediumItalic-WebM, 500, italic)
@include dd-font-face(s, EuclidSquare-Semibold-WebM, 600, normal);
// +dd-font-face(si, EuclidSquare-SemiboldItalic-WebM, 600, italic)
@include dd-font-face(b, EuclidSquare-Bold-WebM, bold, normal);
// +dd-font-face(bi, EuclidSquare-BoldItalic-WebM, bold, italic)


@mixin f-l {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-style: normal; }

@mixin f-li {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-style: italic; }

@mixin f-r {
  font-family: $font-family-sans-serif;
  font-weight: normal;
  font-style: normal; }

@mixin f-ri {
  font-family: $font-family-sans-serif;
  font-weight: normal;
  font-style: italic; }

@mixin f-m {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  font-style: normal; }

@mixin f-mi {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  font-style: italic; }

@mixin f-s {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-style: normal; }

@mixin f-si {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-style: italic; }

@mixin f-b {
  font-weight: bold;
  font-style: normal; }

@mixin f-bi {
  font-weight: bold;
  font-style: italic; }

// FONT ANTIALIASING
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
