.dd-testimonial-slider {
  .slick-list {
    transition: all 0.5s ease-in-out; }

  &__image {
    height: 440px;
    background-size: cover;
    background-position: center; }


  &__content {
    background-color: $gray-200;
    position: relative;
    padding: 40px;

    @include mq($until: md) {
      padding-bottom: 60px;
      text-align: center; }

    blockquote {
      display: block;
      color: $primary; } }

  .slick-dots {
    left: calc(50% + 30px);
    bottom: 30px;
    text-align: left;
    width: 40%;
    transition: all 0.3s ease-in-out;

    @include mq($until: md) {
      text-align: center;
      left: 0px;
      width: 100%;
      bottom: 50px;
      position: relative; } } }
