@mixin stretch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

// $min-size: minimum element $property size in $unit specified
// $max-size: maximum element $property size in $unit specified
// $min-width: the screen width in $units where you want to lock in the $min-size
// $max-width: the screen width in $units where you want to lock in the $max-size
// $property: the property of the element that you would like to scale/lock
// $unit: the units which all measurements are taken in
@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px) {
  #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));

  @include mq($until: $min-width + 0px) {
    #{$property}: #{$min-size}#{$unit}; }

  @include mq($from: $max-width + 0px) {
    #{$property}: #{$max-size}#{$unit}; } }
