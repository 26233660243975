.dd-list,
.dd-list__wrapper ul {
  position: relative;
  // column-count: 2
  // column-gap: 40px

  li {
    position: relative;
    display: inline-block;
    width: 100%;
    list-style: none;
    margin-left: -20px;
    font-size: 1.25em;
    line-height: 1.6em;

    &::before {
      content: '';
      display: block;
      height: 7px;
      width: 7px;
      background: $primary;
      border-radius: 50px;
      position: absolute;
      left: -16px;
      top: 0.6em; } } }

