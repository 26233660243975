.icon-3d-druck {
  width: 283px;
  height: 283px;
}

.icon-agrartechnik {
  width: 283px;
  height: 283px;
}

.icon-alpinindustrie {
  width: 283px;
  height: 283px;
}

.icon-anwendungsgebiete {
  width: 283px;
  height: 283px;
}

.icon-appstore_badge {
  width: 135px;
  height: 40px;
}

.icon-arrow__list {
  width: 18px;
  height: 11px;
}

.icon-ausgangslage {
  width: 283px;
  height: 283px;
}

.icon-bahntechnik {
  width: 283px;
  height: 283px;
}

.icon-ballistik {
  width: 283px;
  height: 283px;
}

.icon-ballistische-platten-2 {
  width: 283px;
  height: 283px;
}

.icon-ballistische-platten {
  width: 283px;
  height: 283px;
}

.icon-bauindustrie {
  width: 283px;
  height: 283px;
}

.icon-beratung {
  width: 283px;
  height: 283px;
}

.icon-biokompabilitaet {
  width: 283px;
  height: 283px;
}

.icon-blockspan {
  width: 283px;
  height: 283px;
}

.icon-busindustrie {
  width: 283px;
  height: 283px;
}

.icon-chemikalienbestaendige-kunststoffe {
  width: 283px;
  height: 283px;
}

.icon-chemikalienbestaendigkeit {
  width: 283px;
  height: 283px;
}

.icon-chemischer-behaelter-anlagenbau {
  width: 283px;
  height: 283px;
}

.icon-detektierbare-kunststoffe-2 {
  width: 283px;
  height: 283px;
}

.icon-detektierbare-kunststoffe {
  width: 283px;
  height: 283px;
}

.icon-download_pdf {
  width: 25px;
  height: 27px;
}

.icon-eigenschaften {
  width: 283px;
  height: 283px;
}

.icon-einfache-verarbeitung {
  width: 283px;
  height: 283px;
}

.icon-elektro {
  width: 283px;
  height: 283px;
}

.icon-elektronik {
  width: 283px;
  height: 283px;
}

.icon-erneuerbare-energien {
  width: 283px;
  height: 283px;
}

.icon-esd-kunststoffe {
  width: 283px;
  height: 283px;
}

.icon-facebook {
  width: 264px;
  height: 512px;
}

.icon-fahrzeugbau {
  width: 283px;
  height: 283px;
}

.icon-fertigteile-1 {
  width: 283px;
  height: 283px;
}

.icon-fertigteile-2 {
  width: 283px;
  height: 283px;
}

.icon-foerdertechnik-automation {
  width: 283px;
  height: 283px;
}

.icon-folien-rollenware {
  width: 283px;
  height: 283px;
}

.icon-formpressteile {
  width: 283px;
  height: 283px;
}

.icon-gleiten {
  width: 283px;
  height: 283px;
}

.icon-grid_view {
  width: 23px;
  height: 23px;
}

.icon-gusspolyamid-sonderteile-1 {
  width: 283px;
  height: 283px;
}

.icon-gusspolyamid-sonderteile-2 {
  width: 283px;
  height: 283px;
}

.icon-haccp {
  width: 283px;
  height: 283px;
}

.icon-hochwertige-oberflaeche {
  width: 283px;
  height: 283px;
}

.icon-hohe-prozesssicherheit {
  width: 283px;
  height: 283px;
}

.icon-hygiene-schneidplatten {
  width: 283px;
  height: 283px;
}

.icon-hygienisch {
  width: 283px;
  height: 283px;
}

.icon-isolationseigenschaft {
  width: 283px;
  height: 283px;
}

.icon-korrosionsbestaendigkeit {
  width: 283px;
  height: 283px;
}

.icon-kritischer-ladezustand {
  width: 283px;
  height: 283px;
}

.icon-kunststoff-zerspanung {
  width: 283px;
  height: 283px;
}

.icon-labensdauer {
  width: 283px;
  height: 283px;
}

.icon-lackierbarkeit {
  width: 283px;
  height: 283px;
}

.icon-lebensmittelkontakt {
  width: 283px;
  height: 283px;
}

.icon-leichte-handhabung {
  width: 283px;
  height: 283px;
}

.icon-leichte-reinigung-1 {
  width: 283px;
  height: 283px;
}

.icon-linkedin {
  width: 448.1px;
  height: 512px;
}

.icon-list_view {
  width: 22px;
  height: 24px;
}

.icon-location {
  width: 11px;
  height: 15px;
}

.icon-loetmasken-2 {
  width: 283px;
  height: 283px;
}

.icon-loetmasken {
  width: 283px;
  height: 283px;
}

.icon-luft-raumfahrt {
  width: 283px;
  height: 283px;
}

.icon-maschinen-anlagenbau {
  width: 283px;
  height: 283px;
}

.icon-mechanische-stabilitaet {
  width: 283px;
  height: 283px;
}

.icon-medizintechnik-orthopaedie {
  width: 283px;
  height: 283px;
}

.icon-messerschonend {
  width: 283px;
  height: 283px;
}

.icon-mikrozerspante-bauteile {
  width: 283px;
  height: 283px;
}

.icon-neutronenabschirmung {
  width: 283px;
  height: 283px;
}

.icon-nuklearindustrie {
  width: 283px;
  height: 283px;
}

.icon-oel-gas {
  width: 283px;
  height: 283px;
}

.icon-papierindustrie {
  width: 283px;
  height: 283px;
}

.icon-partner {
  width: 283px;
  height: 283px;
}

.icon-perforierte-platten {
  width: 283px;
  height: 283px;
}

.icon-phone {
  width: 29px;
  height: 29px;
}

.icon-platten-bloecke {
  width: 283px;
  height: 283px;
}

.icon-platten {
  width: 283px;
  height: 283px;
}

.icon-playstore_badge {
  width: 135px;
  height: 40px;
}

.icon-print {
  width: 26px;
  height: 27px;
}

.icon-produktprogramm {
  width: 283px;
  height: 283px;
}

.icon-profile-2 {
  width: 283px;
  height: 283px;
}

.icon-profile-schweissdraht-behaelterbau {
  width: 283px;
  height: 283px;
}

.icon-profile {
  width: 283px;
  height: 283px;
}

.icon-rammschutz-2 {
  width: 283px;
  height: 283px;
}

.icon-rammschutz {
  width: 283px;
  height: 283px;
}

.icon-rammschutzleisten {
  width: 283px;
  height: 283px;
}

.icon-reinraumtechnik {
  width: 283px;
  height: 283px;
}

.icon-ringe-wickelbauteile-neu {
  width: 283px;
  height: 283px;
}

.icon-robustes-material-1 {
  width: 283px;
  height: 283px;
}

.icon-robustes-material {
  width: 283px;
  height: 283px;
}

.icon-rutschhemmend {
  width: 283px;
  height: 283px;
}

.icon-schiffs-bootsbau {
  width: 283px;
  height: 283px;
}

.icon-schneeflocke {
  width: 283px;
  height: 283px;
}

.icon-schuettgutforderung {
  width: 283px;
  height: 283px;
}

.icon-schweissdraht {
  width: 283px;
  height: 283px;
}

.icon-schwerentflammbar {
  width: 283px;
  height: 283px;
}

.icon-schwingungsdaempfung {
  width: 283px;
  height: 283px;
}

.icon-search {
  width: 15px;
  height: 15px;
}

.icon-share_mail {
  width: 33px;
  height: 23px;
}

.icon-solar {
  width: 283px;
  height: 283px;
}

.icon-sport-freizeit {
  width: 283px;
  height: 283px;
}

.icon-stanzplatten {
  width: 283px;
  height: 283px;
}

.icon-sterilisierbarkeit {
  width: 283px;
  height: 283px;
}

.icon-temperaturbestaendigkeit {
  width: 283px;
  height: 283px;
}

.icon-tieftemperaturisolierung {
  width: 283px;
  height: 283px;
}

.icon-tiefziehtechnik {
  width: 283px;
  height: 283px;
}

.icon-tribologische-eigenschaften {
  width: 283px;
  height: 283px;
}

.icon-trimming_layers {
  width: 283px;
  height: 283px;
}

.icon-trink-abwassertechnik {
  width: 283px;
  height: 283px;
}

.icon-twitter {
  width: 300px;
  height: 244px;
}

.icon-verbindungselemente {
  width: 283px;
  height: 283px;
}

.icon-verkehrstechnik-infrastruktur {
  width: 283px;
  height: 283px;
}

.icon-wasser-hafenbau {
  width: 283px;
  height: 283px;
}

.icon-werbetechnik {
  width: 283px;
  height: 283px;
}

.icon-werkstoff {
  width: 283px;
  height: 283px;
}

.icon-windkraft {
  width: 283px;
  height: 283px;
}

.icon-wirtschaftl-vorteil {
  width: 283px;
  height: 283px;
}

.icon-youtube {
  width: 71px;
  height: 50px;
}

.icon-zugfestigkeit {
  width: 283px;
  height: 283px;
}

.icon-zuschnitt-service {
  width: 283px;
  height: 283px;
}

