body {
  font-family: "EuclidSquare Regular", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class^="display-"] {
  font-family: "EuclidSquare Semibold";
  font-weight: bold;

  &.display-4 {
    font-size: 2.5rem;
  }
}

p {
  font-size: 1.25em;
  line-height: 1.5em;
}

strong {
  font-weight: bold;
  font-family: "EuclidSquare Semibold";
}

sup {
  top: -0.5em;
  font-size: 0.6em;
  font-weight: bold;
}