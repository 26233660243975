
.dd-page-container {
  display: flex;
  flex-direction: row; }


.dd-page-sidebar {
  flex-flow: start;
  align-self: stretch;
  min-height: 100vh;

  a {
    color: $white; } }

.dd-page-content {
  align-self: stretch;
  width: 100%;
  min-height: 100vh;

  @include mq($from: sm) {
    width: calc(100vw - 56px); }

  @include mq($from: md) {
    width: calc(100vw - 250px - 56px); } // Volle Breite minus Banderole und Sidebar


  .content-section {
    padding: 6rem 2rem 4rem;

    @include mq($until: sm) {
      padding: {
        right: 0rem;
        left: 0rem; } }

    @include mq($from: xl) {
      padding: {
        top: 6rem;
        bottom: 6rem; } } } }




// ----------------------------
// sidebar (still messy)
// ----------------------------

#sidebar {
  width: 250px;
  height: 100%;
  z-index: 999;
  background: $roe-blue;
  color: #fff;
  transition: all 0.3s; }


#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc; }


#sidebar ul.components {
  padding: 40px 0; }


#sidebar ul p {
    color: #fff;
    padding: 10px; }


#sidebar ul li a {
  padding: 2px;
  font-size: 1.1em;
  display: block;

  &.active {
    color: $cyan !important; } }


#sidebar ul li {
  > a {
    @include f-s; } }

#sidebar ul li a:hover {
  text-decoration: none {
    // color: #7386D5
 } }    // background: #fff


#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: $roe-blue; }


ul ul a {
    font-size: 0.9em;
    padding-left: 30px;
    background: #6d7fcc; }


// Styling Sidebar dropdowns
a[data-toggle="collapse"] {
  position: relative; }


.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%); }


#sidebarCollapse {
  width: 40px;
  height: 40px;
  border-style: none;
  cursor: pointer; }


#sidebarCollapse span {
  width: 60%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #fff;
  transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375); }


#sidebarCollapse span:first-of-type {
  transform: rotate(45deg) translate(2px, 2px); }

#sidebarCollapse span:nth-of-type(2) {
  opacity: 0; }

#sidebarCollapse span:last-of-type {
  transform: rotate(-45deg) translate(1px, -1px); }

#sidebarCollapse.active span {
  transform: none;
  opacity: 1;
  margin: 3px auto;
  background: #fff; }



@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
        transform: rotateY(90deg); }

    #sidebar.active {
        margin-left: 0;
        transform: none; }

    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
        transform: none;
        opacity: 1;
        margin: 5px auto; }

    #sidebarCollapse.active span {
        margin: 0 auto; }

    #sidebarCollapse.active span:first-of-type {
        transform: rotate(45deg) translate(2px, 2px); }

    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0; }

    #sidebarCollapse.active span:last-of-type {
        transform: rotate(-45deg) translate(1px, -1px); } }


// ----------------------------
// topsearch
// ----------------------------
.search-form {
  min-width: 150px;
  width: 30vw;
  max-width: 100%; }

.search-form .input-group {
  transition: all 0.35s, border-radius 0s;
  height: 32px;
  margin-top: -5px;


  input.form-control {
    padding-right: 20px;
    background: transparent;
    box-shadow: none;
    display: block;
    background-color: #fff;
    border: 1px solid #ccc; }

  &input.form-control, &input.form-control {
    width: 520px; }


  i.form-control-feedback {
    position: absolute;
    top: 2px;
    right: 1px;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: $roe-shiny-silver;
    left: initial;
    font-size: 14px;
    background: white; } }



.search-form .input-group input.form-control:focus::-webkit-input-placeholder {
  display: none;
  color: transparent; }

.search-form .input-group input.form-control:focus:-moz-placeholder {
  /* Firefox 18- */
  display: none;
  color: transparent; }

.search-form .input-group input.form-control:focus::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
  color: transparent; }

.search-form .input-group input.form-control:focus:-ms-input-placeholder {
  display: none;
  color: transparent; }


.dd-vertical-divider {
  border-right: 1px solid $roe-deep-blue;
  height: 100%;
  margin-right: 50%; }
