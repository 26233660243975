.dd-download {
  height: 40px;

  a {
    text-decoration: none; }

  .icon {
    position: relative;
    top: 7px; }

  &__text {
    margin-left: 12px;
    display: inline-block;
    font-weight: bold; }

  &__data {
    margin-left: 10px;
    font-weight: normal; } }
