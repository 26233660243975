@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900");

// @font-face {
//   font-family: "EuclidSquare-Medium";
//   src: url(fonts/EuclidSquare-Medium-WebM.woff2) format("woff2"),
//     url(fonts/EuclidSquare-Medium-WebM.woff) format("woff"),
//     url(fonts/EuclidSquare-Medium-WebM.eot) format("embedded-opentype"),
//     url(fonts/EuclidSquare-Medium-WebM.ttf) format("truetype");
// }

@font-face {
  font-family: "EuclidSquare Regular";
  src: url(fonts/EuclidSquare-Regular-WebM.woff2) format("woff2"),
    url(fonts/EuclidSquare-Regular-WebM.woff) format("woff"),
    url(fonts/EuclidSquare-Regular-WebM.eot#iefix) format("embedded-opentype"),
    url(fonts/EuclidSquare-Regular-WebM.ttf) format("truetype");
}

@font-face {
  font-family: "EuclidSquare Semibold";
  src: url(fonts/EuclidSquare-Semibold-WebM.woff2) format("woff2"),
    url(fonts/EuclidSquare-Semibold-WebM.woff) format("woff"),
    url(fonts/EuclidSquare-Semibold-WebM.eot#iefix) format("embedded-opentype"),
    url(fonts/EuclidSquare-Semibold-WebM.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "EuclidSquare Bold";
  src: url(fonts/EuclidSquare-Bold-WebM.woff2) format("woff2"),
    url(fonts/EuclidSquare-Bold-WebM.woff) format("woff"),
    url(fonts/EuclidSquare-Bold-WebM.eot#iefix) format("embedded-opentype"),
    url(fonts/EuclidSquare-Bold-WebM.ttf) format("truetype");
  font-weight: bolder;
}
