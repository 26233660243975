.dd-pagination {
  ul {
    float: right; }

  &__pages {
    float: left; }

  &__arrow {

    &-right {
      transform: rotate(-90deg); }

    &-left {
      transform: rotate(90deg); } }

  &__nr {
    padding-right: 8px;
    padding-left: 8px;

    &:hover {
      background-color: $secondary;
      a {
        color: $white;
        text-decoration: none; } } }

  .icon, .icon__inner {
    svg use {
      fill: $primary;
      stroke: $primary; } } }
