// Bootstrap overrides

.container-fluid {
  max-width: 1200px;
}

.container-fullwidth {
  // max-width: 1920px;
  // margin: auto;
  max-width: 100%;
}