.dd-intro {
  &__headline {
    @include t-h1;
    margin-bottom: 1.5rem; }

  &__text {
    @include f-m;

    @include mq($until: sm);
    font-size: 16px; } }
