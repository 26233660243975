@mixin dd-feature-mobile-height {
  height: 0;
  min-height: auto;
  padding-top: 80%; }


.dd-feature {
  display: block;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  background: rgba($white, 0.9);


  &:hover {
    &::after {
      opacity: 0.3; }

    .dd-feature__gradient {
      opacity: 1; }
    .dd-feature__image {
      transform: scale(1); } }

  &::after {
    @include stretch;
    content: '';
    background-color: rgba($indigo, 0.55);
    opacity: 0;
    z-index: 9;
    transition: all 0.4s ease-in-out; }

  &__image {
    width: 100%;
    height: 440px;
    background-size: cover;
    background-position: center;
    opacity: 1;
    transition: all 0.4s ease-in-out;
    transform: scale(1.025);

    @include mq($until: sm) {
      @include dd-feature-mobile-height; } }

  &__content {
    padding: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    color: $white;
    z-index: 10; }

  &__meta {
    padding-bottom: 6px;

    ul,
    ul li {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 1; }

    li {
      display: inline-block;

      &::after {
        content: '|';
        display: inline-block;
        padding: {
          right: 3px;
          left: 6px; }
        position: relative; }

      &:last-child::after {
        content: ''; } } }

  &__title {
    @include t-h2;
    margin-bottom: 24px;
    color: #fff; } }

// #FIXME nicht mobile getestet.
.dd-feature--textonly {
  max-width: unset;
  min-height: 440px;

  @include mq($until: sm) {
    @include dd-feature-mobile-height; }

  &::before {
    content: none; }

  .dd-feature__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include mq($until: sm) {
      @include dd-feature-mobile-height; } } }

.dd-feature__gradient {
  content: '';
  @include stretch;
  background: linear-gradient(45deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 60%);
  transition: all 0.2s ease-in-out; }

.dd-feature--keyfact-key {
  @include f-m;
  @include css-lock(80, 140, 320, 980);
  line-height: inherit;

  &-wrapper {
    width: 80%; } }

