.dd-padding {
  padding: $dd-spacing;

  &--top {
    padding-top: $dd-spacing; }

  &--right {
    padding-right: $dd-spacing; }

  &--bottom {
    padding-bottom: $dd-spacing; }

  &--left {
    padding-left: $dd-spacing; } }


.dd-margin {
  margin: $dd-spacing;

  &--top {
    margin-top: $dd-spacing; }

  &--right {
    margin-right: $dd-spacing; }

  &--bottom {
    margin-bottom: $dd-spacing; }

  &--left {
    margin-left: $dd-spacing; } }
