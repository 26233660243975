.dd-block {

  &--columns {
    h2:first-child,
    h3:first-child {
      padding-top: 0 !important; } }


  &--headline {
    + .dd-block--images,
    + .dd-product-slider {
      padding-top: 10px; } }


  &--images {
    + .dd-block--table, {
      margin-top: 10px; } }


  &--line {
    padding: 0;

    @include mq($from: md) {
      padding: 1rem 0; }

    @include mq($from: lg) {
      padding: 1.5rem 0; } }


  &--text {
    ol {
      padding-left: 24px;

      li {
        @include f-l;
        color: $blue;
        font-size: 22px;
        line-height: 1.5;
        font-weight: bold;
        font-style: normal;

        @include mq($until: lg) {
          font-size: $font-size-lg; }

        @include mq($until: sm) {
          font-size: $font-size-base; } } } }


  &--video {
    .col > div {
      // ReactPlayer
      width: auto !important;
      height: auto !important; } } }
