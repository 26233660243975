// SVG Sprite
.icon--wrapper {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

// Icon
.icon {
  display: inline-block;
  position: relative;

  &__inner {
    display: block;
    position: relative;
    padding-top: 100%; }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    use {
      fill: $primary; } } }


@mixin icon($size, $color) {
  .icon {
    position: relative;
    color: $color;

    @include mq($until: sm) {
      width: $size - 20px; }

    @if $size {
      font-size: $size;
      width: $size; }

    @content;

    svg use {
      fill: $color;
      stroke: $color; } } }


@import "./icons/svg-symbols";
