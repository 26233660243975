.dd-table {
  @include f-r;
  font-size: 16px;

  a {
    font-weight: 500;

    &:hover,
    &:focus {
      text-decoration: none; } }

  .icon {
    width: 14px;
    position: relative;
    top: 2px; }

  th, td {
    border-color: $primary;
    padding: {
      top: 0.8rem;
      bottom: 0.8rem; }

    &.danger {
      color: $secondary; } }

  thead {
    th {
      border-top: none;
      border-bottom: 2px solid $primary;
      font-weight: bold;
      color: $primary;
      font-size: 16px;

      &.col-10 {
        width: 10%; }
      &.col-25 {
        width: 25%; }
      &.col-33 {
        width: 33%; }
      &.col-50 {
        width: 50%; }
      &.col-66 {
        width: 66%; }
      &.col-75 {
        width: 75%; }

      .icon, .icon__inner {
        svg use {
          fill: $secondary;
          stroke: $secondary; } } } }

  tbody {
    &.no-border {
      border-bottom: none; }

    tr:first-child {
      th, td {
        border-top: none; } } }

  // Bootstrap hover
  &.table-hover {
    tbody {
      tr:hover {
        transition: all $trans ease-in-out;
        background-color: $gray-200; } } } }
