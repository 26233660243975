$roe-blue: #0064b3;
$roe-deep-blue: #034067;
$roe-tech-teal: #00959d;
$roe-vibrant-cyan: #04bbee;

$roechling-orange: #ff671f;
$roe-fresh-green: #6cc24a;
$roe-graphite-grey: #253746;
$roe-purple-plum: #840b55;
$roe-shiny-silver: #a2aaad;
$roe-vivid-yellow: #e1e000;

$roe-value-green: #038f45;
$roe-value-darkgreen: #004d33;
$roe-value-darkestgreen: #003830;
$roe-value-blue: #004aa6;
$roe-value-purple: #001c69;


// Custom Bootstrap Variables
// @see vendor/bootstrap/_variable.scss

$gray-200: #e9e9e9; // light gray
$gray-400: #a2aaad; // shiny silver
$gray-600: #4e575e; // dark gray
$gray-800: #253746; // graphit

$white: #fff;
$black: #000;
$blue: #0064b3; // röchling blue
$cyan: #04bbee; // vibrant cyan
$indigo: #034067; // deep pblue
$purple: #840b55; // purple plum
$orange: #ff671f; // bright orange
$yellow: #e1e000; // vivid yellow
$green: #6cc24a; // fresh green
$teal: #00959d; // technical teal

// defaults
$pink: #e83e8c;
$red: #dc3545;

$primary: $blue;
$secondary: $cyan;

// add normal colors to theme colors to use them with .bg-COLOR
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $blue,
    "secondary": $cyan,
    "success": $green,
    "info": $cyan,
    "warning": $yellow,
    "danger": $red,
    "light": $gray-200,
    "dark": $gray-800,
    "blue": $blue,
    "cyan": $cyan,
    "indigo": $indigo,
    "purple": $purple,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal
  ),
  $theme-colors
);

// //  ===== GRID =====

// $grid-gutter-width: 30px;

// // ===== TYPOGRAPHY =====

// $font-family-sans-serif: "EuclidSquare Regular", sans-serif;;

// $lead-font-weight: normal;
// $lead-font-size: 22px;

// $h0-font-size: 50px; // not official
// $h1-font-size: 38px;
// $h2-font-size: 28px;
// $h3-font-size: 22px;
// $h4-font-size: 20px;
// $h5-font-size: 20px;
// $h6-font-size: 20px;

// // shorthands
// $fs-lead: $lead-font-size;
// $fs-h0: $h0-font-size;
// $fs-h1: $h1-font-size;
// $fs-h2: $h2-font-size;
// $fs-h3: $h3-font-size;
// $fs-h4: $h4-font-size;
// $fs-h5: $h5-font-size;
// $fs-h6: $h6-font-size;

// // ===== TABLES =====

// $table-cell-padding: 0.5rem;

// // ===== COMPONENTS =====

// // $box-shadow-sm
// $box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
// $box-shadow-lg: 0 4px 20px 0 rgba(0, 0, 0, 0.2);


// $btn-border-radius:    0;
// $btn-border-radius-lg: 0;
// $btn-border-radius-sm: 0;
