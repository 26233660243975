@charset "UTF-8";
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900");
@media (min-width: 26.25em) {
  html {
    margin: 0; } }

@media (min-width: 36em) {
  html {
    margin: 0; } }

@media (min-width: 48em) {
  html {
    margin: 0; } }

@media (min-width: 62em) {
  html {
    margin: 0; } }

@media (min-width: 75em) {
  html {
    margin: 0; } }

@media (min-width: 87.5em) {
  html {
    margin: 0; } }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0064b3;
  --indigo: #034067;
  --purple: #840b55;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #ff671f;
  --yellow: #e1e000;
  --green: #6cc24a;
  --teal: #00959d;
  --cyan: #04bbee;
  --white: #fff;
  --gray: #4e575e;
  --gray-dark: #253746;
  --primary: #0064b3;
  --secondary: #04bbee;
  --success: #6cc24a;
  --info: #04bbee;
  --warning: #e1e000;
  --danger: #dc3545;
  --light: #e9e9e9;
  --dark: #253746;
  --blue: #0064b3;
  --cyan: #04bbee;
  --indigo: #034067;
  --purple: #840b55;
  --orange: #ff671f;
  --yellow: #e1e000;
  --green: #6cc24a;
  --teal: #00959d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "EuclidSquare Regular", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "EuclidSquare Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0064b3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #003967;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #4e575e;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.375rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.375rem; }

h4, .h4 {
  font-size: 1.375rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1.25rem; }

.lead {
  font-size: 1.375rem;
  font-weight: normal; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #4e575e; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #4e575e; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d4ea; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7aaed7; }

.table-hover .table-primary:hover {
  background-color: #a4c8e4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a4c8e4; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b9ecfa; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7cdcf6; }

.table-hover .table-secondary:hover {
  background-color: #a1e6f8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #a1e6f8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d6eecc; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b3dfa1; }

.table-hover .table-success:hover {
  background-color: #c7e8b9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c7e8b9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b9ecfa; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7cdcf6; }

.table-hover .table-info:hover {
  background-color: #a1e6f8; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a1e6f8; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7f6b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #efef7a; }

.table-hover .table-warning:hover {
  background-color: #f4f3a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f4f3a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f9f9; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f4f4; }

.table-hover .table-light:hover {
  background-color: #ececec; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececec; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c7cb; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8e979f; }

.table-hover .table-dark:hover {
  background-color: #b4babf; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4babf; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #b8d4ea; }

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #7aaed7; }

.table-hover .table-blue:hover {
  background-color: #a4c8e4; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #a4c8e4; }

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #b9ecfa; }

.table-cyan th,
.table-cyan td,
.table-cyan thead th,
.table-cyan tbody + tbody {
  border-color: #7cdcf6; }

.table-hover .table-cyan:hover {
  background-color: #a1e6f8; }
  .table-hover .table-cyan:hover > td,
  .table-hover .table-cyan:hover > th {
    background-color: #a1e6f8; }

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #b8cad4; }

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #7c9cb0; }

.table-hover .table-indigo:hover {
  background-color: #a8beca; }
  .table-hover .table-indigo:hover > td,
  .table-hover .table-indigo:hover > th {
    background-color: #a8beca; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #ddbbcf; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #bf80a7; }

.table-hover .table-purple:hover {
  background-color: #d5aac3; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #d5aac3; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #ffd4c0; }

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #ffb08b; }

.table-hover .table-orange:hover {
  background-color: #ffc3a7; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #ffc3a7; }

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #f7f6b8; }

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #efef7a; }

.table-hover .table-yellow:hover {
  background-color: #f4f3a1; }
  .table-hover .table-yellow:hover > td,
  .table-hover .table-yellow:hover > th {
    background-color: #f4f3a1; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #d6eecc; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #b3dfa1; }

.table-hover .table-green:hover {
  background-color: #c7e8b9; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #c7e8b9; }

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #b8e1e4; }

.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody + tbody {
  border-color: #7ac8cc; }

.table-hover .table-teal:hover {
  background-color: #a6d9dd; }
  .table-hover .table-teal:hover > td,
  .table-hover .table-teal:hover > th {
    background-color: #a6d9dd; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #253746;
  border-color: #324b5f; }

.table .thead-light th {
  color: #495057;
  background-color: #e9e9e9;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #253746; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #324b5f; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #a2aaad;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #34a5ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
  .form-control::placeholder {
    color: #4e575e;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9e9e9;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #4e575e; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6cc24a; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(108, 194, 74, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #6cc24a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236cc24a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #6cc24a;
    box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #6cc24a;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23253746' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236cc24a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #6cc24a;
    box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #6cc24a; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #6cc24a; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #6cc24a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #8bcf70;
  background-color: #8bcf70; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6cc24a; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #6cc24a; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #6cc24a;
  box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23253746' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0064b3;
  border-color: #0064b3; }
  .btn-primary:hover {
    color: #fff;
    background-color: #004f8d;
    border-color: #004880; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 123, 190, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #004880;
    border-color: #004073; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 123, 190, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #04bbee;
  border-color: #04bbee; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #039dc8;
    border-color: #0394bc; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 197, 241, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0394bc;
    border-color: #038aaf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 197, 241, 0.5); }

.btn-success {
  color: #212529;
  background-color: #6cc24a;
  border-color: #6cc24a; }
  .btn-success:hover {
    color: #fff;
    background-color: #5aac3a;
    border-color: #55a237; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 170, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #6cc24a;
    border-color: #6cc24a; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #55a237;
    border-color: #509933; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(97, 170, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #04bbee;
  border-color: #04bbee; }
  .btn-info:hover {
    color: #fff;
    background-color: #039dc8;
    border-color: #0394bc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 197, 241, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0394bc;
    border-color: #038aaf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 197, 241, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #e1e000;
  border-color: #e1e000; }
  .btn-warning:hover {
    color: #212529;
    background-color: #bbba00;
    border-color: #aead00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 196, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #e1e000;
    border-color: #e1e000; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #aead00;
    border-color: #a1a100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 196, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #e9e9e9;
  border-color: #e9e9e9; }
  .btn-light:hover {
    color: #212529;
    background-color: #d6d6d6;
    border-color: #d0d0d0; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 204, 204, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #e9e9e9;
    border-color: #e9e9e9; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d0d0d0;
    border-color: #c9c9c9; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 204, 204, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #253746;
  border-color: #253746; }
  .btn-dark:hover {
    color: #fff;
    background-color: #18232d;
    border-color: #131d25; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(70, 85, 98, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #253746;
    border-color: #253746; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #131d25;
    border-color: #0f161c; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(70, 85, 98, 0.5); }

.btn-blue {
  color: #fff;
  background-color: #0064b3;
  border-color: #0064b3; }
  .btn-blue:hover {
    color: #fff;
    background-color: #004f8d;
    border-color: #004880; }
  .btn-blue:focus, .btn-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 123, 190, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #004880;
    border-color: #004073; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 123, 190, 0.5); }

.btn-cyan {
  color: #fff;
  background-color: #04bbee;
  border-color: #04bbee; }
  .btn-cyan:hover {
    color: #fff;
    background-color: #039dc8;
    border-color: #0394bc; }
  .btn-cyan:focus, .btn-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 197, 241, 0.5); }
  .btn-cyan.disabled, .btn-cyan:disabled {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
  .btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #0394bc;
    border-color: #038aaf; }
    .btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 197, 241, 0.5); }

.btn-indigo {
  color: #fff;
  background-color: #034067;
  border-color: #034067; }
  .btn-indigo:hover {
    color: #fff;
    background-color: #022942;
    border-color: #022135; }
  .btn-indigo:focus, .btn-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 93, 126, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #034067;
    border-color: #034067; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #022135;
    border-color: #011a29; }
    .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 93, 126, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #840b55;
  border-color: #840b55; }
  .btn-purple:hover {
    color: #fff;
    background-color: #61083e;
    border-color: #550737; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 111, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #840b55;
    border-color: #840b55; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #550737;
    border-color: #49062f; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 48, 111, 0.5); }

.btn-orange {
  color: #fff;
  background-color: #ff671f;
  border-color: #ff671f; }
  .btn-orange:hover {
    color: #fff;
    background-color: #f85000;
    border-color: #eb4c00; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 126, 65, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #ff671f;
    border-color: #ff671f; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #eb4c00;
    border-color: #de4700; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 126, 65, 0.5); }

.btn-yellow {
  color: #212529;
  background-color: #e1e000;
  border-color: #e1e000; }
  .btn-yellow:hover {
    color: #212529;
    background-color: #bbba00;
    border-color: #aead00; }
  .btn-yellow:focus, .btn-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 196, 6, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #212529;
    background-color: #e1e000;
    border-color: #e1e000; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #212529;
    background-color: #aead00;
    border-color: #a1a100; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 196, 6, 0.5); }

.btn-green {
  color: #212529;
  background-color: #6cc24a;
  border-color: #6cc24a; }
  .btn-green:hover {
    color: #fff;
    background-color: #5aac3a;
    border-color: #55a237; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 170, 69, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #212529;
    background-color: #6cc24a;
    border-color: #6cc24a; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #55a237;
    border-color: #509933; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(97, 170, 69, 0.5); }

.btn-teal {
  color: #fff;
  background-color: #00959d;
  border-color: #00959d; }
  .btn-teal:hover {
    color: #fff;
    background-color: #007177;
    border-color: #00656a; }
  .btn-teal:focus, .btn-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 165, 172, 0.5); }
  .btn-teal.disabled, .btn-teal:disabled {
    color: #fff;
    background-color: #00959d;
    border-color: #00959d; }
  .btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #00656a;
    border-color: #00585d; }
    .btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 165, 172, 0.5); }

.btn-outline-primary {
  color: #0064b3;
  border-color: #0064b3; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0064b3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.5); }

.btn-outline-secondary {
  color: #04bbee;
  border-color: #04bbee; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #04bbee;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }

.btn-outline-success {
  color: #6cc24a;
  border-color: #6cc24a; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #6cc24a;
    border-color: #6cc24a; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #6cc24a;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #6cc24a;
    border-color: #6cc24a; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.5); }

.btn-outline-info {
  color: #04bbee;
  border-color: #04bbee; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #04bbee;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }

.btn-outline-warning {
  color: #e1e000;
  border-color: #e1e000; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #e1e000;
    border-color: #e1e000; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 224, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #e1e000;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #e1e000;
    border-color: #e1e000; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 224, 0, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #e9e9e9;
  border-color: #e9e9e9; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #e9e9e9;
    border-color: #e9e9e9; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 233, 233, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e9e9e9;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e9e9e9;
    border-color: #e9e9e9; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 233, 233, 0.5); }

.btn-outline-dark {
  color: #253746;
  border-color: #253746; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #253746;
    border-color: #253746; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(37, 55, 70, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #253746;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #253746;
    border-color: #253746; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(37, 55, 70, 0.5); }

.btn-outline-blue {
  color: #0064b3;
  border-color: #0064b3; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #0064b3;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.5); }

.btn-outline-cyan {
  color: #04bbee;
  border-color: #04bbee; }
  .btn-outline-cyan:hover {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
  .btn-outline-cyan:focus, .btn-outline-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }
  .btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
    color: #04bbee;
    background-color: transparent; }
  .btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #fff;
    background-color: #04bbee;
    border-color: #04bbee; }
    .btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }

.btn-outline-indigo {
  color: #034067;
  border-color: #034067; }
  .btn-outline-indigo:hover {
    color: #fff;
    background-color: #034067;
    border-color: #034067; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(3, 64, 103, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #034067;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #034067;
    border-color: #034067; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(3, 64, 103, 0.5); }

.btn-outline-purple {
  color: #840b55;
  border-color: #840b55; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #840b55;
    border-color: #840b55; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(132, 11, 85, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #840b55;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #840b55;
    border-color: #840b55; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 11, 85, 0.5); }

.btn-outline-orange {
  color: #ff671f;
  border-color: #ff671f; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #ff671f;
    border-color: #ff671f; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 31, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #ff671f;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #ff671f;
    border-color: #ff671f; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 103, 31, 0.5); }

.btn-outline-yellow {
  color: #e1e000;
  border-color: #e1e000; }
  .btn-outline-yellow:hover {
    color: #212529;
    background-color: #e1e000;
    border-color: #e1e000; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 224, 0, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #e1e000;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #212529;
    background-color: #e1e000;
    border-color: #e1e000; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 224, 0, 0.5); }

.btn-outline-green {
  color: #6cc24a;
  border-color: #6cc24a; }
  .btn-outline-green:hover {
    color: #212529;
    background-color: #6cc24a;
    border-color: #6cc24a; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #6cc24a;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #212529;
    background-color: #6cc24a;
    border-color: #6cc24a; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.5); }

.btn-outline-teal {
  color: #00959d;
  border-color: #00959d; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #00959d;
    border-color: #00959d; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 149, 157, 0.5); }
  .btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #00959d;
    background-color: transparent; }
  .btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #00959d;
    border-color: #00959d; }
    .btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 149, 157, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0064b3;
  text-decoration: none; }
  .btn-link:hover {
    color: #003967;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #4e575e;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9e9e9; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0064b3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #4e575e;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #4e575e;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9e9e9;
  border: 1px solid #a2aaad;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0064b3;
    background-color: #0064b3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #34a5ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #67bcff;
    border-color: #67bcff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #4e575e; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9e9e9; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0064b3;
  background-color: #0064b3; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 100, 179, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 100, 179, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 100, 179, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 100, 179, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23253746' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #a2aaad;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #34a5ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #4e575e;
    background-color: #e9e9e9; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #34a5ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9e9e9; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #a2aaad;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9e9e9;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0064b3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #67bcff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0064b3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #67bcff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0064b3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #67bcff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #4e575e;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9e9e9 #e9e9e9 #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #4e575e;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0064b3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9e9e9;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #4e575e;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #4e575e; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0064b3;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #003967;
    text-decoration: none;
    background-color: #e9e9e9;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0064b3;
  border-color: #0064b3; }

.page-item.disabled .page-link {
  color: #4e575e;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0064b3; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #004880; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #04bbee; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #0394bc; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }

.badge-success {
  color: #212529;
  background-color: #6cc24a; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #55a237; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.5); }

.badge-info {
  color: #fff;
  background-color: #04bbee; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #0394bc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #e1e000; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #aead00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(225, 224, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #e9e9e9; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d0d0d0; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 233, 233, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #253746; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #131d25; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(37, 55, 70, 0.5); }

.badge-blue {
  color: #fff;
  background-color: #0064b3; }
  a.badge-blue:hover, a.badge-blue:focus {
    color: #fff;
    background-color: #004880; }
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 100, 179, 0.5); }

.badge-cyan {
  color: #fff;
  background-color: #04bbee; }
  a.badge-cyan:hover, a.badge-cyan:focus {
    color: #fff;
    background-color: #0394bc; }
  a.badge-cyan:focus, a.badge-cyan.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(4, 187, 238, 0.5); }

.badge-indigo {
  color: #fff;
  background-color: #034067; }
  a.badge-indigo:hover, a.badge-indigo:focus {
    color: #fff;
    background-color: #022135; }
  a.badge-indigo:focus, a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 64, 103, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #840b55; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #550737; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(132, 11, 85, 0.5); }

.badge-orange {
  color: #fff;
  background-color: #ff671f; }
  a.badge-orange:hover, a.badge-orange:focus {
    color: #fff;
    background-color: #eb4c00; }
  a.badge-orange:focus, a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 31, 0.5); }

.badge-yellow {
  color: #212529;
  background-color: #e1e000; }
  a.badge-yellow:hover, a.badge-yellow:focus {
    color: #212529;
    background-color: #aead00; }
  a.badge-yellow:focus, a.badge-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(225, 224, 0, 0.5); }

.badge-green {
  color: #212529;
  background-color: #6cc24a; }
  a.badge-green:hover, a.badge-green:focus {
    color: #212529;
    background-color: #55a237; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 194, 74, 0.5); }

.badge-teal {
  color: #fff;
  background-color: #00959d; }
  a.badge-teal:hover, a.badge-teal:focus {
    color: #fff;
    background-color: #00656a; }
  a.badge-teal:focus, a.badge-teal.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 149, 157, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9e9e9;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #00345d;
  background-color: #cce0f0;
  border-color: #b8d4ea; }
  .alert-primary hr {
    border-top-color: #a4c8e4; }
  .alert-primary .alert-link {
    color: #00172a; }

.alert-secondary {
  color: #02617c;
  background-color: #cdf1fc;
  border-color: #b9ecfa; }
  .alert-secondary hr {
    border-top-color: #a1e6f8; }
  .alert-secondary .alert-link {
    color: #013a4a; }

.alert-success {
  color: #386526;
  background-color: #e2f3db;
  border-color: #d6eecc; }
  .alert-success hr {
    border-top-color: #c7e8b9; }
  .alert-success .alert-link {
    color: #234018; }

.alert-info {
  color: #02617c;
  background-color: #cdf1fc;
  border-color: #b9ecfa; }
  .alert-info hr {
    border-top-color: #a1e6f8; }
  .alert-info .alert-link {
    color: #013a4a; }

.alert-warning {
  color: #757400;
  background-color: #f9f9cc;
  border-color: #f7f6b8; }
  .alert-warning hr {
    border-top-color: #f4f3a1; }
  .alert-warning .alert-link {
    color: #424100; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #797979;
  background-color: #fbfbfb;
  border-color: #f9f9f9; }
  .alert-light hr {
    border-top-color: #ececec; }
  .alert-light .alert-link {
    color: #606060; }

.alert-dark {
  color: #131d24;
  background-color: #d3d7da;
  border-color: #c2c7cb; }
  .alert-dark hr {
    border-top-color: #b4babf; }
  .alert-dark .alert-link {
    color: #010203; }

.alert-blue {
  color: #00345d;
  background-color: #cce0f0;
  border-color: #b8d4ea; }
  .alert-blue hr {
    border-top-color: #a4c8e4; }
  .alert-blue .alert-link {
    color: #00172a; }

.alert-cyan {
  color: #02617c;
  background-color: #cdf1fc;
  border-color: #b9ecfa; }
  .alert-cyan hr {
    border-top-color: #a1e6f8; }
  .alert-cyan .alert-link {
    color: #013a4a; }

.alert-indigo {
  color: #022136;
  background-color: #cdd9e1;
  border-color: #b8cad4; }
  .alert-indigo hr {
    border-top-color: #a8beca; }
  .alert-indigo .alert-link {
    color: #000305; }

.alert-purple {
  color: #45062c;
  background-color: #e6cedd;
  border-color: #ddbbcf; }
  .alert-purple hr {
    border-top-color: #d5aac3; }
  .alert-purple .alert-link {
    color: #16020e; }

.alert-orange {
  color: #853610;
  background-color: #ffe1d2;
  border-color: #ffd4c0; }
  .alert-orange hr {
    border-top-color: #ffc3a7; }
  .alert-orange .alert-link {
    color: #57240b; }

.alert-yellow {
  color: #757400;
  background-color: #f9f9cc;
  border-color: #f7f6b8; }
  .alert-yellow hr {
    border-top-color: #f4f3a1; }
  .alert-yellow .alert-link {
    color: #424100; }

.alert-green {
  color: #386526;
  background-color: #e2f3db;
  border-color: #d6eecc; }
  .alert-green hr {
    border-top-color: #c7e8b9; }
  .alert-green .alert-link {
    color: #234018; }

.alert-teal {
  color: #004d52;
  background-color: #cceaeb;
  border-color: #b8e1e4; }
  .alert-teal hr {
    border-top-color: #a6d9dd; }
  .alert-teal .alert-link {
    color: #001d1f; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9e9e9;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0064b3;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9e9e9; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #4e575e;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0064b3;
    border-color: #0064b3; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #00345d;
  background-color: #b8d4ea; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00345d;
    background-color: #a4c8e4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00345d;
    border-color: #00345d; }

.list-group-item-secondary {
  color: #02617c;
  background-color: #b9ecfa; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #02617c;
    background-color: #a1e6f8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #02617c;
    border-color: #02617c; }

.list-group-item-success {
  color: #386526;
  background-color: #d6eecc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #386526;
    background-color: #c7e8b9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #386526;
    border-color: #386526; }

.list-group-item-info {
  color: #02617c;
  background-color: #b9ecfa; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #02617c;
    background-color: #a1e6f8; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #02617c;
    border-color: #02617c; }

.list-group-item-warning {
  color: #757400;
  background-color: #f7f6b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #757400;
    background-color: #f4f3a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #757400;
    border-color: #757400; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #797979;
  background-color: #f9f9f9; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #797979;
    background-color: #ececec; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #797979;
    border-color: #797979; }

.list-group-item-dark {
  color: #131d24;
  background-color: #c2c7cb; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #131d24;
    background-color: #b4babf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #131d24;
    border-color: #131d24; }

.list-group-item-blue {
  color: #00345d;
  background-color: #b8d4ea; }
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #00345d;
    background-color: #a4c8e4; }
  .list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #00345d;
    border-color: #00345d; }

.list-group-item-cyan {
  color: #02617c;
  background-color: #b9ecfa; }
  .list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
    color: #02617c;
    background-color: #a1e6f8; }
  .list-group-item-cyan.list-group-item-action.active {
    color: #fff;
    background-color: #02617c;
    border-color: #02617c; }

.list-group-item-indigo {
  color: #022136;
  background-color: #b8cad4; }
  .list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
    color: #022136;
    background-color: #a8beca; }
  .list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #022136;
    border-color: #022136; }

.list-group-item-purple {
  color: #45062c;
  background-color: #ddbbcf; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #45062c;
    background-color: #d5aac3; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #45062c;
    border-color: #45062c; }

.list-group-item-orange {
  color: #853610;
  background-color: #ffd4c0; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #853610;
    background-color: #ffc3a7; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #853610;
    border-color: #853610; }

.list-group-item-yellow {
  color: #757400;
  background-color: #f7f6b8; }
  .list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
    color: #757400;
    background-color: #f4f3a1; }
  .list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #757400;
    border-color: #757400; }

.list-group-item-green {
  color: #386526;
  background-color: #d6eecc; }
  .list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
    color: #386526;
    background-color: #c7e8b9; }
  .list-group-item-green.list-group-item-action.active {
    color: #fff;
    background-color: #386526;
    border-color: #386526; }

.list-group-item-teal {
  color: #004d52;
  background-color: #b8e1e4; }
  .list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
    color: #004d52;
    background-color: #a6d9dd; }
  .list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #004d52;
    border-color: #004d52; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #4e575e;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "EuclidSquare Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "EuclidSquare Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0064b3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #004880 !important; }

.bg-secondary {
  background-color: #04bbee !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0394bc !important; }

.bg-success {
  background-color: #6cc24a !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #55a237 !important; }

.bg-info {
  background-color: #04bbee !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0394bc !important; }

.bg-warning {
  background-color: #e1e000 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #aead00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #e9e9e9 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d0d0d0 !important; }

.bg-dark {
  background-color: #253746 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #131d25 !important; }

.bg-blue {
  background-color: #0064b3 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #004880 !important; }

.bg-cyan {
  background-color: #04bbee !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #0394bc !important; }

.bg-indigo {
  background-color: #034067 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #022135 !important; }

.bg-purple {
  background-color: #840b55 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #550737 !important; }

.bg-orange {
  background-color: #ff671f !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #eb4c00 !important; }

.bg-yellow {
  background-color: #e1e000 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #aead00 !important; }

.bg-green {
  background-color: #6cc24a !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #55a237 !important; }

.bg-teal {
  background-color: #00959d !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #00656a !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0064b3 !important; }

.border-secondary {
  border-color: #04bbee !important; }

.border-success {
  border-color: #6cc24a !important; }

.border-info {
  border-color: #04bbee !important; }

.border-warning {
  border-color: #e1e000 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #e9e9e9 !important; }

.border-dark {
  border-color: #253746 !important; }

.border-blue {
  border-color: #0064b3 !important; }

.border-cyan {
  border-color: #04bbee !important; }

.border-indigo {
  border-color: #034067 !important; }

.border-purple {
  border-color: #840b55 !important; }

.border-orange {
  border-color: #ff671f !important; }

.border-yellow {
  border-color: #e1e000 !important; }

.border-green {
  border-color: #6cc24a !important; }

.border-teal {
  border-color: #00959d !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2) !important; }

.shadow-lg {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0064b3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #003967 !important; }

.text-secondary {
  color: #04bbee !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0380a3 !important; }

.text-success {
  color: #6cc24a !important; }

a.text-success:hover, a.text-success:focus {
  color: #4b8f30 !important; }

.text-info {
  color: #04bbee !important; }

a.text-info:hover, a.text-info:focus {
  color: #0380a3 !important; }

.text-warning {
  color: #e1e000 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #959400 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #e9e9e9 !important; }

a.text-light:hover, a.text-light:focus {
  color: #c3c3c3 !important; }

.text-dark {
  color: #253746 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0b1014 !important; }

.text-blue {
  color: #0064b3 !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #003967 !important; }

.text-cyan {
  color: #04bbee !important; }

a.text-cyan:hover, a.text-cyan:focus {
  color: #0380a3 !important; }

.text-indigo {
  color: #034067 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #01121d !important; }

.text-purple {
  color: #840b55 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #3d0528 !important; }

.text-orange {
  color: #ff671f !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #d24300 !important; }

.text-yellow {
  color: #e1e000 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #959400 !important; }

.text-green {
  color: #6cc24a !important; }

a.text-green:hover, a.text-green:focus {
  color: #4b8f30 !important; }

.text-teal {
  color: #00959d !important; }

a.text-teal:hover, a.text-teal:focus {
  color: #004c51 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #4e575e !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.container-fluid {
  max-width: 1200px; }

.container-fullwidth {
  max-width: 100%; }

.dd-image-card {
  overflow: hidden;
  position: relative; }
  .dd-image-card::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0; }
  .dd-image-card:hover::after {
    opacity: .5; }
  .dd-image-card__image {
    transition: transform .3s ease-in-out; }
    .dd-image-card:hover .dd-image-card__image {
      transform: scale(1.05); }
  .dd-image-card__actions {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); }
  .dd-image-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s ease-in-out; }
    .dd-image-card:hover .dd-image-card__overlay {
      opacity: 1; }

/* Device = Most of the Smartphones Mobiles (Portrait) */
/* Device = Low Resolution Tablets, Mobiles (Landscape) */
/* Device = Tablets, Ipads (portrait) */
/* Device = Laptops, Desktops */
/* Device = Desktops */
/* Higher Resolution Screens */
@font-face {
  font-family: "EuclidSquare Regular";
  src: url(fonts/EuclidSquare-Regular-WebM.woff2) format("woff2"), url(fonts/EuclidSquare-Regular-WebM.woff) format("woff"), url(fonts/EuclidSquare-Regular-WebM.eot#iefix) format("embedded-opentype"), url(fonts/EuclidSquare-Regular-WebM.ttf) format("truetype"); }

@font-face {
  font-family: "EuclidSquare Semibold";
  src: url(fonts/EuclidSquare-Semibold-WebM.woff2) format("woff2"), url(fonts/EuclidSquare-Semibold-WebM.woff) format("woff"), url(fonts/EuclidSquare-Semibold-WebM.eot#iefix) format("embedded-opentype"), url(fonts/EuclidSquare-Semibold-WebM.ttf) format("truetype");
  font-weight: bold; }

@font-face {
  font-family: "EuclidSquare Bold";
  src: url(fonts/EuclidSquare-Bold-WebM.woff2) format("woff2"), url(fonts/EuclidSquare-Bold-WebM.woff) format("woff"), url(fonts/EuclidSquare-Bold-WebM.eot#iefix) format("embedded-opentype"), url(fonts/EuclidSquare-Bold-WebM.ttf) format("truetype");
  font-weight: bolder; }

.mb-6 {
  margin-bottom: 4rem !important; }

.mb-7 {
  margin-bottom: 5rem !important; }

.mb-8 {
  margin-bottom: 6rem !important; }

.mb-9 {
  margin-bottom: 7rem !important; }

.mb-10 {
  margin-bottom: 8rem !important; }

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-9 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.my-10 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-9 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.py-10 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

body {
  font-family: "EuclidSquare Regular", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6,
[class^="display-"] {
  font-family: "EuclidSquare Semibold";
  font-weight: bold; }
  h1.display-4,
  h2.display-4,
  h3.display-4,
  h4.display-4,
  h5.display-4,
  h6.display-4,
  [class^="display-"].display-4 {
    font-size: 2.5rem; }

p {
  font-size: 1.25em;
  line-height: 1.5em; }

strong {
  font-weight: bold;
  font-family: "EuclidSquare Semibold"; }

sup {
  top: -0.5em;
  font-size: 0.6em;
  font-weight: bold; }

@font-face {
  font-family: "EuclidSquare Regular", sans-serif;
  src: url("fonts/EuclidSquare-Regular-WebM.eot");
  src: local("☺︎"), url("fonts/EuclidSquare-Regular-WebM.eot?#iefix") format("embedded-opentype"), url("fonts/EuclidSquare-Regular-WebM.woff2") format("woff2"), url("fonts/EuclidSquare-Regular-WebM.woff") format("woff"), url("fonts/EuclidSquare-Regular-WebM.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.f-r {
  font-family: r; }

@font-face {
  font-family: "EuclidSquare Regular", sans-serif;
  src: url("fonts/EuclidSquare-Medium-WebM.eot");
  src: local("☺︎"), url("fonts/EuclidSquare-Medium-WebM.eot?#iefix") format("embedded-opentype"), url("fonts/EuclidSquare-Medium-WebM.woff2") format("woff2"), url("fonts/EuclidSquare-Medium-WebM.woff") format("woff"), url("fonts/EuclidSquare-Medium-WebM.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

.f-m {
  font-family: m; }

@font-face {
  font-family: "EuclidSquare Regular", sans-serif;
  src: url("fonts/EuclidSquare-Semibold-WebM.eot");
  src: local("☺︎"), url("fonts/EuclidSquare-Semibold-WebM.eot?#iefix") format("embedded-opentype"), url("fonts/EuclidSquare-Semibold-WebM.woff2") format("woff2"), url("fonts/EuclidSquare-Semibold-WebM.woff") format("woff"), url("fonts/EuclidSquare-Semibold-WebM.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

.f-s {
  font-family: s; }

@font-face {
  font-family: "EuclidSquare Regular", sans-serif;
  src: url("fonts/EuclidSquare-Bold-WebM.eot");
  src: local("☺︎"), url("fonts/EuclidSquare-Bold-WebM.eot?#iefix") format("embedded-opentype"), url("fonts/EuclidSquare-Bold-WebM.woff2") format("woff2"), url("fonts/EuclidSquare-Bold-WebM.woff") format("woff"), url("fonts/EuclidSquare-Bold-WebM.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.f-b {
  font-family: b; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  position: relative;
  min-width: 320px; }

img {
  max-width: 100%; }

h1, .h1 {
  font-weight: bold;
  font-style: normal;
  font-size: 38px;
  line-height: 1.5; }
  @media (max-width: 35.99em) {
    h1, .h1 {
      font-size: 28px; } }

h2, .h2 {
  font-weight: bold;
  font-style: normal;
  font-size: 28px;
  line-height: 1.5;
  padding-bottom: 10px; }
  @media (max-width: 35.99em) {
    h2, .h2 {
      font-size: 22px; } }

h3, .h3 {
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  line-height: 1.5;
  color: #0064b3; }
  @media (max-width: 35.99em) {
    h3, .h3 {
      font-size: 1.25rem; } }

h4, .h4 {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  line-height: 1.5; }
  @media (max-width: 35.99em) {
    h4, .h4 {
      font-size: 1rem; } }
  .dd-block--text h4, .dd-block--text .h4 {
    padding-bottom: 1.1rem; }

h5, .h5 {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 1.5; }
  @media (max-width: 35.99em) {
    h5, .h5 {
      font-size: 1rem; } }

h6, .h6 {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 1.5; }
  @media (max-width: 35.99em) {
    h6, .h6 {
      font-size: 1rem; } }

.text-gray-200 {
  color: #e9e9e9; }

.text-gray-400 {
  color: #a2aaad; }

.text-gray-600 {
  color: #4e575e; }

.text-gray-800 {
  color: #253746; }

.text-blue {
  color: #0064b3; }

.dd-padding {
  padding: 80px; }
  .dd-padding--top {
    padding-top: 80px; }
  .dd-padding--right {
    padding-right: 80px; }
  .dd-padding--bottom {
    padding-bottom: 80px; }
  .dd-padding--left {
    padding-left: 80px; }

.dd-margin {
  margin: 80px; }
  .dd-margin--top {
    margin-top: 80px; }
  .dd-margin--right {
    margin-right: 80px; }
  .dd-margin--bottom {
    margin-bottom: 80px; }
  .dd-margin--left {
    margin-left: 80px; }

.icon--wrapper {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.icon {
  display: inline-block;
  position: relative; }
  .icon__inner {
    display: block;
    position: relative;
    padding-top: 100%; }
  .icon svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .icon svg use {
      fill: #0064b3; }

.icon-3d-druck {
  width: 283px;
  height: 283px; }

.icon-agrartechnik {
  width: 283px;
  height: 283px; }

.icon-alpinindustrie {
  width: 283px;
  height: 283px; }

.icon-anwendungsgebiete {
  width: 283px;
  height: 283px; }

.icon-appstore_badge {
  width: 135px;
  height: 40px; }

.icon-arrow__list {
  width: 18px;
  height: 11px; }

.icon-ausgangslage {
  width: 283px;
  height: 283px; }

.icon-bahntechnik {
  width: 283px;
  height: 283px; }

.icon-ballistik {
  width: 283px;
  height: 283px; }

.icon-ballistische-platten-2 {
  width: 283px;
  height: 283px; }

.icon-ballistische-platten {
  width: 283px;
  height: 283px; }

.icon-bauindustrie {
  width: 283px;
  height: 283px; }

.icon-beratung {
  width: 283px;
  height: 283px; }

.icon-biokompabilitaet {
  width: 283px;
  height: 283px; }

.icon-blockspan {
  width: 283px;
  height: 283px; }

.icon-busindustrie {
  width: 283px;
  height: 283px; }

.icon-chemikalienbestaendige-kunststoffe {
  width: 283px;
  height: 283px; }

.icon-chemikalienbestaendigkeit {
  width: 283px;
  height: 283px; }

.icon-chemischer-behaelter-anlagenbau {
  width: 283px;
  height: 283px; }

.icon-detektierbare-kunststoffe-2 {
  width: 283px;
  height: 283px; }

.icon-detektierbare-kunststoffe {
  width: 283px;
  height: 283px; }

.icon-download_pdf {
  width: 25px;
  height: 27px; }

.icon-eigenschaften {
  width: 283px;
  height: 283px; }

.icon-einfache-verarbeitung {
  width: 283px;
  height: 283px; }

.icon-elektro {
  width: 283px;
  height: 283px; }

.icon-elektronik {
  width: 283px;
  height: 283px; }

.icon-erneuerbare-energien {
  width: 283px;
  height: 283px; }

.icon-esd-kunststoffe {
  width: 283px;
  height: 283px; }

.icon-facebook {
  width: 264px;
  height: 512px; }

.icon-fahrzeugbau {
  width: 283px;
  height: 283px; }

.icon-fertigteile-1 {
  width: 283px;
  height: 283px; }

.icon-fertigteile-2 {
  width: 283px;
  height: 283px; }

.icon-foerdertechnik-automation {
  width: 283px;
  height: 283px; }

.icon-folien-rollenware {
  width: 283px;
  height: 283px; }

.icon-formpressteile {
  width: 283px;
  height: 283px; }

.icon-gleiten {
  width: 283px;
  height: 283px; }

.icon-grid_view {
  width: 23px;
  height: 23px; }

.icon-gusspolyamid-sonderteile-1 {
  width: 283px;
  height: 283px; }

.icon-gusspolyamid-sonderteile-2 {
  width: 283px;
  height: 283px; }

.icon-haccp {
  width: 283px;
  height: 283px; }

.icon-hochwertige-oberflaeche {
  width: 283px;
  height: 283px; }

.icon-hohe-prozesssicherheit {
  width: 283px;
  height: 283px; }

.icon-hygiene-schneidplatten {
  width: 283px;
  height: 283px; }

.icon-hygienisch {
  width: 283px;
  height: 283px; }

.icon-isolationseigenschaft {
  width: 283px;
  height: 283px; }

.icon-korrosionsbestaendigkeit {
  width: 283px;
  height: 283px; }

.icon-kritischer-ladezustand {
  width: 283px;
  height: 283px; }

.icon-kunststoff-zerspanung {
  width: 283px;
  height: 283px; }

.icon-labensdauer {
  width: 283px;
  height: 283px; }

.icon-lackierbarkeit {
  width: 283px;
  height: 283px; }

.icon-lebensmittelkontakt {
  width: 283px;
  height: 283px; }

.icon-leichte-handhabung {
  width: 283px;
  height: 283px; }

.icon-leichte-reinigung-1 {
  width: 283px;
  height: 283px; }

.icon-linkedin {
  width: 448.1px;
  height: 512px; }

.icon-list_view {
  width: 22px;
  height: 24px; }

.icon-location {
  width: 11px;
  height: 15px; }

.icon-loetmasken-2 {
  width: 283px;
  height: 283px; }

.icon-loetmasken {
  width: 283px;
  height: 283px; }

.icon-luft-raumfahrt {
  width: 283px;
  height: 283px; }

.icon-maschinen-anlagenbau {
  width: 283px;
  height: 283px; }

.icon-mechanische-stabilitaet {
  width: 283px;
  height: 283px; }

.icon-medizintechnik-orthopaedie {
  width: 283px;
  height: 283px; }

.icon-messerschonend {
  width: 283px;
  height: 283px; }

.icon-mikrozerspante-bauteile {
  width: 283px;
  height: 283px; }

.icon-neutronenabschirmung {
  width: 283px;
  height: 283px; }

.icon-nuklearindustrie {
  width: 283px;
  height: 283px; }

.icon-oel-gas {
  width: 283px;
  height: 283px; }

.icon-papierindustrie {
  width: 283px;
  height: 283px; }

.icon-partner {
  width: 283px;
  height: 283px; }

.icon-perforierte-platten {
  width: 283px;
  height: 283px; }

.icon-phone {
  width: 29px;
  height: 29px; }

.icon-platten-bloecke {
  width: 283px;
  height: 283px; }

.icon-platten {
  width: 283px;
  height: 283px; }

.icon-playstore_badge {
  width: 135px;
  height: 40px; }

.icon-print {
  width: 26px;
  height: 27px; }

.icon-produktprogramm {
  width: 283px;
  height: 283px; }

.icon-profile-2 {
  width: 283px;
  height: 283px; }

.icon-profile-schweissdraht-behaelterbau {
  width: 283px;
  height: 283px; }

.icon-profile {
  width: 283px;
  height: 283px; }

.icon-rammschutz-2 {
  width: 283px;
  height: 283px; }

.icon-rammschutz {
  width: 283px;
  height: 283px; }

.icon-rammschutzleisten {
  width: 283px;
  height: 283px; }

.icon-reinraumtechnik {
  width: 283px;
  height: 283px; }

.icon-ringe-wickelbauteile-neu {
  width: 283px;
  height: 283px; }

.icon-robustes-material-1 {
  width: 283px;
  height: 283px; }

.icon-robustes-material {
  width: 283px;
  height: 283px; }

.icon-rutschhemmend {
  width: 283px;
  height: 283px; }

.icon-schiffs-bootsbau {
  width: 283px;
  height: 283px; }

.icon-schneeflocke {
  width: 283px;
  height: 283px; }

.icon-schuettgutforderung {
  width: 283px;
  height: 283px; }

.icon-schweissdraht {
  width: 283px;
  height: 283px; }

.icon-schwerentflammbar {
  width: 283px;
  height: 283px; }

.icon-schwingungsdaempfung {
  width: 283px;
  height: 283px; }

.icon-search {
  width: 15px;
  height: 15px; }

.icon-share_mail {
  width: 33px;
  height: 23px; }

.icon-solar {
  width: 283px;
  height: 283px; }

.icon-sport-freizeit {
  width: 283px;
  height: 283px; }

.icon-stanzplatten {
  width: 283px;
  height: 283px; }

.icon-sterilisierbarkeit {
  width: 283px;
  height: 283px; }

.icon-temperaturbestaendigkeit {
  width: 283px;
  height: 283px; }

.icon-tieftemperaturisolierung {
  width: 283px;
  height: 283px; }

.icon-tiefziehtechnik {
  width: 283px;
  height: 283px; }

.icon-tribologische-eigenschaften {
  width: 283px;
  height: 283px; }

.icon-trimming_layers {
  width: 283px;
  height: 283px; }

.icon-trink-abwassertechnik {
  width: 283px;
  height: 283px; }

.icon-twitter {
  width: 300px;
  height: 244px; }

.icon-verbindungselemente {
  width: 283px;
  height: 283px; }

.icon-verkehrstechnik-infrastruktur {
  width: 283px;
  height: 283px; }

.icon-wasser-hafenbau {
  width: 283px;
  height: 283px; }

.icon-werbetechnik {
  width: 283px;
  height: 283px; }

.icon-werkstoff {
  width: 283px;
  height: 283px; }

.icon-windkraft {
  width: 283px;
  height: 283px; }

.icon-wirtschaftl-vorteil {
  width: 283px;
  height: 283px; }

.icon-youtube {
  width: 71px;
  height: 50px; }

.icon-zugfestigkeit {
  width: 283px;
  height: 283px; }

.icon-zuschnitt-service {
  width: 283px;
  height: 283px; }

.dd-social-icon .icon {
  width: 48px;
  height: 48px;
  background-color: #0064b3;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out; }
  .dd-social-icon .icon:hover, .dd-social-icon .icon:focus {
    background-color: #04bbee; }
  .dd-social-icon .icon svg use {
    fill: #fff; }

.dd-breadcrumb {
  padding-top: 20px; }
  .dd-breadcrumb ul {
    list-style: none;
    padding-left: 0px; }
  .dd-breadcrumb li {
    display: inline-block;
    font-size: 12px;
    padding-right: 20px;
    color: #0064b3;
    position: relative; }
    .dd-breadcrumb li a {
      color: #4e575e; }
      .dd-breadcrumb li a:after {
        content: '';
        width: 5px;
        height: 5px;
        border-top: 1px solid #4e575e;
        border-right: 1px solid #4e575e;
        display: block;
        position: relative;
        transform: rotate(45deg);
        position: absolute;
        right: 7px;
        top: 7px; }

.dd-image--small img {
  display: block;
  width: 50%;
  margin: auto; }

.dd-image__caption {
  font-size: 16px;
  padding-bottom: 10px; }

.dd-video {
  position: relative;
  padding-top: 56.25%;
  height: 0; }
  .dd-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }
  .dd-video__caption {
    padding-top: 24px;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%); }

.dd-pagination ul {
  float: right; }

.dd-pagination__pages {
  float: left; }

.dd-pagination__arrow-right {
  transform: rotate(-90deg); }

.dd-pagination__arrow-left {
  transform: rotate(90deg); }

.dd-pagination__nr {
  padding-right: 8px;
  padding-left: 8px; }
  .dd-pagination__nr:hover {
    background-color: #04bbee; }
    .dd-pagination__nr:hover a {
      color: #fff;
      text-decoration: none; }

.dd-pagination .icon svg use, .dd-pagination .icon__inner svg use {
  fill: #0064b3;
  stroke: #0064b3; }

.dd-appstore__badge {
  width: 154px;
  height: auto; }

.dd-download {
  height: 40px; }
  .dd-download a {
    text-decoration: none; }
  .dd-download .icon {
    position: relative;
    top: 7px; }
  .dd-download__text {
    margin-left: 12px;
    display: inline-block;
    font-weight: bold; }
  .dd-download__data {
    margin-left: 10px;
    font-weight: normal; }

.dd-block--columns h2:first-child,
.dd-block--columns h3:first-child {
  padding-top: 0 !important; }

.dd-block--headline + .dd-block--images,
.dd-block--headline + .dd-product-slider {
  padding-top: 10px; }

.dd-block--images + .dd-block--table {
  margin-top: 10px; }

.dd-block--line {
  padding: 0; }
  @media (min-width: 48em) {
    .dd-block--line {
      padding: 1rem 0; } }
  @media (min-width: 62em) {
    .dd-block--line {
      padding: 1.5rem 0; } }

.dd-block--text ol {
  padding-left: 24px; }
  .dd-block--text ol li {
    font-family: "EuclidSquare Regular", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #0064b3;
    font-size: 22px;
    line-height: 1.5;
    font-weight: bold;
    font-style: normal; }
    @media (max-width: 61.99em) {
      .dd-block--text ol li {
        font-size: 1.25rem; } }
    @media (max-width: 35.99em) {
      .dd-block--text ol li {
        font-size: 1rem; } }

.dd-block--video .col > div {
  width: auto !important;
  height: auto !important; }

.dd-hero {
  position: relative;
  color: #fff;
  max-height: 100vh;
  height: calc(100vh - 77px); }
  @media (max-width: 61.99em) {
    .dd-hero {
      height: unset;
      max-height: unset;
      margin-top: 51px;
      min-height: unset; } }
  @media (max-width: 61.99em) {
    .dd-hero:before {
      content: '';
      padding-top: 56.25%;
      width: 100%;
      display: block; } }
  .dd-hero .overlay {
    opacity: 0.2; }
  .dd-hero__inner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0; }
    @media (max-width: 61.99em) {
      .dd-hero__inner video {
        top: 0;
        left: 0;
        min-width: unset;
        min-height: unset;
        height: auto;
        width: 100%;
        transform: none;
        -webkit-transform: none; } }
  .dd-hero__container {
    max-width: calc(100vw - 57px);
    display: inline-block;
    vertical-align: bottom;
    display: flex;
    align-items: center; }
    @media (max-width: 61.99em) {
      .dd-hero__container {
        top: 0;
        left: 0;
        min-width: unset;
        min-height: unset;
        height: auto;
        width: 100%;
        transform: none;
        -webkit-transform: none; } }
  .dd-hero__content {
    padding: 20px 10px;
    color: #fff;
    max-width: 520px; }
  .dd-hero__title {
    font-size: 60px;
    line-height: 1.23; }
    @media (max-width: 47.99em) {
      .dd-hero__title {
        font-size: 48px; } }
    @media (max-width: 35.99em) {
      .dd-hero__title {
        font-size: 36px; } }
    @media (max-width: 26.24em) {
      .dd-hero__title {
        font-size: 32px; } }
  .dd-hero__arrow {
    position: relative;
    bottom: 100px;
    width: 100%;
    display: block;
    z-index: 1; }
    @media (max-width: 47.99em) {
      .dd-hero__arrow {
        display: none; } }
    .dd-hero__arrow:hover {
      text-decoration: none; }
    .dd-hero__arrow:after {
      content: '';
      margin: auto;
      width: 30px;
      height: 30px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      position: absolute;
      transform: rotate(45deg);
      display: block;
      bottom: -35px;
      z-index: 3;
      left: calc(50% - 15px); }
    .dd-hero__arrow span {
      display: block;
      text-align: center;
      width: 100%;
      color: #fff;
      line-height: 33px;
      font-size: 22px;
      font-family: "EuclidSquare Regular", sans-serif;
      font-weight: 600;
      font-style: normal; }

.dd-intro__headline {
  font-weight: bold;
  font-style: normal;
  font-size: 38px;
  line-height: 1.5;
  margin-bottom: 1.5rem; }
  @media (max-width: 35.99em) {
    .dd-intro__headline {
      font-size: 28px; } }

.dd-intro__text {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px; }

.dd-footer {
  color: #fff;
  background-color: #82898e;
  margin-bottom: 0px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3.5rem;
  position: relative;
  z-index: 1018; }
  @media (max-width: 47.99em) {
    .dd-footer {
      padding-left: 0; } }
  .dd-footer__sitemap {
    margin-bottom: 1rem; }
    .dd-footer__sitemap-col {
      margin-bottom: 1.5rem; }
    .dd-footer__sitemap-category {
      font-family: "EuclidSquare Regular", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 18px; }
    .dd-footer__sitemap-list {
      list-style: none;
      display: initial;
      padding-left: 0; }
      .dd-footer__sitemap-list li > a {
        color: #e9e9e9;
        text-decoration: none; }
        .dd-footer__sitemap-list li > a:hover {
          color: #253746; }
  .dd-footer .dd-divider {
    width: 100%;
    height: 1px;
    background-color: #e9e9e9;
    display: block;
    margin-bottom: 2rem; }
  .dd-footer__cta {
    margin-bottom: 1.5rem; }
    .dd-footer__cta-title {
      font-family: "EuclidSquare Regular", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      margin-bottom: 1.5rem; }
    .dd-footer__cta-social ul {
      padding: 0;
      display: inline-flex;
      list-style: none; }
      .dd-footer__cta-social ul li {
        margin-right: 1.5rem; }
      .dd-footer__cta-social ul .icon {
        height: 25px;
        width: 25px; }
        .dd-footer__cta-social ul .icon svg use {
          fill: #fff; }
        .dd-footer__cta-social ul .icon:hover {
          cursor: pointer; }
          .dd-footer__cta-social ul .icon:hover svg use {
            fill: #253746; }
    .dd-footer__cta-buttons button.btn {
      background: #fff;
      color: #686C6F;
      margin-right: 1rem; }
      .dd-footer__cta-buttons button.btn:hover {
        color: #253746; }
  .dd-footer__top {
    position: absolute;
    right: 15px;
    height: 60px;
    width: 30px; }
    .dd-footer__top-icon {
      content: '';
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background: #fff;
      display: block; }
      .dd-footer__top-icon::after {
        content: '';
        display: block;
        height: 11px;
        width: 11px;
        border-top: 3px solid rgba(78, 87, 94, 0.7);
        border-right: 3px solid rgba(78, 87, 94, 0.7);
        transform: rotate(-45deg);
        position: absolute;
        right: 9px;
        top: 12px; }
    .dd-footer__top-text {
      font-size: 10pt;
      text-align: center;
      padding-top: 5px; }
  .dd-footer__menu {
    font-size: 12px;
    color: #fff; }
    .dd-footer__menu ul {
      list-style: none;
      display: flex;
      flex-direction: row-reverse; }
      .dd-footer__menu ul li > a {
        margin-left: 2rem;
        color: #fff; }
        .dd-footer__menu ul li > a:hover {
          color: #253746;
          text-decoration: none; }

footer .navbar {
  min-height: 70px; }

footer ul.navbar-nav > li.nav-item > a.nav-link {
  margin-left: 1rem;
  color: white; }
  footer ul.navbar-nav > li.nav-item > a.nav-link:hover, footer ul.navbar-nav > li.nav-item > a.nav-link:focus {
    color: #04bbee; }

.dd-feature {
  display: block;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9); }
  .dd-feature:hover::after {
    opacity: 0.3; }
  .dd-feature:hover .dd-feature__gradient {
    opacity: 1; }
  .dd-feature:hover .dd-feature__image {
    transform: scale(1); }
  .dd-feature::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: rgba(3, 64, 103, 0.55);
    opacity: 0;
    z-index: 9;
    transition: all 0.4s ease-in-out; }
  .dd-feature__image {
    width: 100%;
    height: 440px;
    background-size: cover;
    background-position: center;
    opacity: 1;
    transition: all 0.4s ease-in-out;
    transform: scale(1.025); }
    @media (max-width: 35.99em) {
      .dd-feature__image {
        height: 0;
        min-height: auto;
        padding-top: 80%; } }
  .dd-feature__content {
    padding: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    z-index: 10; }
  .dd-feature__meta {
    padding-bottom: 6px; }
    .dd-feature__meta ul,
    .dd-feature__meta ul li {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 1; }
    .dd-feature__meta li {
      display: inline-block; }
      .dd-feature__meta li::after {
        content: '|';
        display: inline-block;
        padding-right: 3px;
        padding-left: 6px;
        position: relative; }
      .dd-feature__meta li:last-child::after {
        content: ''; }
  .dd-feature__title {
    font-weight: bold;
    font-style: normal;
    font-size: 28px;
    line-height: 1.5;
    padding-bottom: 10px;
    margin-bottom: 24px;
    color: #fff; }
    @media (max-width: 35.99em) {
      .dd-feature__title {
        font-size: 22px; } }

.dd-feature--textonly {
  max-width: unset;
  min-height: 440px; }
  @media (max-width: 35.99em) {
    .dd-feature--textonly {
      height: 0;
      min-height: auto;
      padding-top: 80%; } }
  .dd-feature--textonly::before {
    content: none; }
  .dd-feature--textonly .dd-feature__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    @media (max-width: 35.99em) {
      .dd-feature--textonly .dd-feature__content {
        height: 0;
        min-height: auto;
        padding-top: 80%; } }

.dd-feature__gradient {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 60%);
  transition: all 0.2s ease-in-out; }

.dd-feature--keyfact-key {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: calc(80px + (140 - 80) * ((100vw - 320px) / (980 - 320)));
  line-height: inherit; }
  @media (max-width: 20em) {
    .dd-feature--keyfact-key {
      font-size: 80px; } }
  @media (min-width: 61.25em) {
    .dd-feature--keyfact-key {
      font-size: 140px; } }
  .dd-feature--keyfact-key-wrapper {
    width: 80%; }

.dd-iconlist {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .dd-iconlist .row {
    margin-left: 0; }
  .dd-iconlist__headline {
    font-weight: bold;
    font-style: normal;
    font-size: 28px;
    line-height: 1.5;
    padding-bottom: 10px;
    color: #0064b3;
    margin-bottom: 3rem; }
    @media (max-width: 35.99em) {
      .dd-iconlist__headline {
        font-size: 22px; } }
  .dd-iconlist__item {
    padding-left: 0;
    margin-bottom: 3rem;
    display: flex; }
    .dd-iconlist__item-icon-container {
      padding-left: 0; }
  .dd-iconlist__icon {
    display: inline-block; }
    .dd-iconlist__icon .icon {
      height: 50px;
      width: 50px; }
      .dd-iconlist__icon .icon svg use {
        fill: #0064b3; }
  .dd-iconlist__text {
    padding-left: 0.5rem; }
    @media (min-width: 48em) {
      .dd-iconlist__text {
        padding-left: 0; } }
    .dd-iconlist__text-title {
      font-family: "EuclidSquare Regular", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 22px;
      line-height: 1.5;
      color: #0064b3; }
      @media (max-width: 35.99em) {
        .dd-iconlist__text-title {
          font-size: 1rem; } }

.dd-slider.slick-slider.slick-dotted {
  margin-bottom: 0; }

.dd-slider .slick-track .slick-slide:nth-child(odd) * {
  color: #0064b3; }

.dd-slider .slick-dots {
  bottom: 40px; }
  @media (max-width: 47.99em) {
    .dd-slider .slick-dots {
      bottom: 20px; } }
  .dd-slider .slick-dots li {
    margin: 0;
    color: #a2aaad !important; }
    .dd-slider .slick-dots li button:hover:before,
    .dd-slider .slick-dots li button:focus:before {
      color: #04bbee; }
    .dd-slider .slick-dots li.slick-active button:hover:before,
    .dd-slider .slick-dots li.slick-active button:focus:before {
      color: #0064b3; }

.dd-cards-press .slick-dots button:before {
  color: #a2aaad; }

.dd-testimonial-slider .slick-list {
  transition: all 0.5s ease-in-out; }

.dd-testimonial-slider__image {
  height: 440px;
  background-size: cover;
  background-position: center; }

.dd-testimonial-slider__content {
  background-color: #e9e9e9;
  position: relative;
  padding: 40px; }
  @media (max-width: 47.99em) {
    .dd-testimonial-slider__content {
      padding-bottom: 60px;
      text-align: center; } }
  .dd-testimonial-slider__content blockquote {
    display: block;
    color: #0064b3; }

.dd-testimonial-slider .slick-dots {
  left: calc(50% + 30px);
  bottom: 30px;
  text-align: left;
  width: 40%;
  transition: all 0.3s ease-in-out; }
  @media (max-width: 47.99em) {
    .dd-testimonial-slider .slick-dots {
      text-align: center;
      left: 0px;
      width: 100%;
      bottom: 50px;
      position: relative; } }

.dd-product-slider img {
  min-width: 100%; }

.dd-product-slider .slick-dots {
  position: absolute;
  bottom: 0; }
  @media (min-width: 62em) {
    .dd-product-slider .slick-dots {
      bottom: 30px; } }
  .dd-product-slider .slick-dots li button:before {
    color: #0064b3;
    font-size: 12px; }
  .dd-product-slider .slick-dots .slick-active li button:before {
    color: #04bbee; }

.dd-product-slider button {
  height: 30px;
  width: 30px; }
  .dd-product-slider button.slick-prev::before, .dd-product-slider button.slick-next::before {
    content: '';
    display: block;
    height: 30px;
    width: 30px;
    border-top: 3px solid #0064b3;
    border-right: 3px solid #0064b3;
    transform: rotate(-45deg);
    position: absolute; }

.dd-product-slider button.slick-prev {
  transform: rotate(-90deg);
  left: -45px; }
  @media (max-width: 35.99em) {
    .dd-product-slider button.slick-prev {
      left: -5px;
      z-index: 1; } }

.dd-product-slider button.slick-next {
  transform: rotate(90deg);
  right: -45px; }
  @media (max-width: 35.99em) {
    .dd-product-slider button.slick-next {
      right: -5px;
      z-index: 1; } }

.dd-subsite-content button.slick-arrow.slick-prev::before, .dd-subsite-content button.slick-arrow.slick-next::before {
  border-top-color: white;
  border-right-color: white; }

.dd-list,
.dd-list__wrapper ul {
  position: relative; }
  .dd-list li,
  .dd-list__wrapper ul li {
    position: relative;
    display: inline-block;
    width: 100%;
    list-style: none;
    margin-left: -20px;
    font-size: 1.25em;
    line-height: 1.6em; }
    .dd-list li::before,
    .dd-list__wrapper ul li::before {
      content: '';
      display: block;
      height: 7px;
      width: 7px;
      background: #0064b3;
      border-radius: 50px;
      position: absolute;
      left: -16px;
      top: 0.6em; }

.dd-table {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px; }
  .dd-table a {
    font-weight: 500; }
    .dd-table a:hover, .dd-table a:focus {
      text-decoration: none; }
  .dd-table .icon {
    width: 14px;
    position: relative;
    top: 2px; }
  .dd-table th, .dd-table td {
    border-color: #0064b3;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
    .dd-table th.danger, .dd-table td.danger {
      color: #04bbee; }
  .dd-table thead th {
    border-top: none;
    border-bottom: 2px solid #0064b3;
    font-weight: bold;
    color: #0064b3;
    font-size: 16px; }
    .dd-table thead th.col-10 {
      width: 10%; }
    .dd-table thead th.col-25 {
      width: 25%; }
    .dd-table thead th.col-33 {
      width: 33%; }
    .dd-table thead th.col-50 {
      width: 50%; }
    .dd-table thead th.col-66 {
      width: 66%; }
    .dd-table thead th.col-75 {
      width: 75%; }
    .dd-table thead th .icon svg use, .dd-table thead th .icon__inner svg use {
      fill: #04bbee;
      stroke: #04bbee; }
  .dd-table tbody.no-border {
    border-bottom: none; }
  .dd-table tbody tr:first-child th, .dd-table tbody tr:first-child td {
    border-top: none; }
  .dd-table.table-hover tbody tr:hover {
    transition: all 0.3s ease-in-out;
    background-color: #e9e9e9; }

.dd-table {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 34px; }
  .dd-table th.col-75 {
    width: 75%; }
  .dd-table th.col-50 {
    width: 50%; }
  .dd-table a {
    font-weight: 500; }
    .dd-table a:hover {
      text-decoration: none; }
  .dd-table th, .dd-table tr, .dd-table td, .dd-table thead th {
    border-color: #0064b3; }
  .dd-table thead th {
    border-top: none;
    border-bottom: 3px solid #0064b3;
    font-weight: bold;
    color: #0064b3;
    font-size: 16px; }
  .dd-table tbody {
    border-bottom: 3px solid #0064b3; }
    .dd-table tbody td {
      transition: padding 0.3s ease-in-out; }
      .dd-table tbody td:hover {
        padding-left: 20px;
        color: #0064b3; }

.dd-tablefilter {
  padding: 20px; }
  .dd-tablefilter .dd-tablefilter--viewmode .dd-icon-view {
    color: white; }
    .dd-tablefilter .dd-tablefilter--viewmode .dd-icon-view .icon {
      height: 30px;
      width: 30px; }
      .dd-tablefilter .dd-tablefilter--viewmode .dd-icon-view .icon svg use {
        fill: #fff; }
  .dd-tablefilter .dd-tablefilter--viewmode ul {
    padding: 0;
    display: inline-flex;
    list-style: none; }
  .dd-tablefilter .dd-tablefilter--viewmode li {
    margin-right: 1.5rem; }
    .dd-tablefilter .dd-tablefilter--viewmode li a {
      display: flex;
      color: white; }
      .dd-tablefilter .dd-tablefilter--viewmode li a .dd-icon-view {
        margin-right: 10px; }
      .dd-tablefilter .dd-tablefilter--viewmode li a:hover {
        cursor: pointer;
        color: #04bbee;
        text-decoration: none; }
        .dd-tablefilter .dd-tablefilter--viewmode li a:hover svg use, .dd-tablefilter .dd-tablefilter--viewmode li a:hover svg:hover use {
          fill: #04bbee; }
  .dd-tablefilter input, .dd-tablefilter select, .dd-tablefilter .form-control {
    border-radius: 0; }
    .dd-tablefilter input::placeholder, .dd-tablefilter select::placeholder, .dd-tablefilter .form-control::placeholder {
      color: #a2aaad; }
  .dd-tablefilter .dd-tablefilter-button-wrapper {
    text-align: right; }

.dd-loading {
  transition: all .5s; }
  .dd-loading__content-wrapper {
    position: relative; }
  .dd-loading__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    z-index: 9999; }
    .dd-loading__content-wrapper .dd-loading__wrapper {
      position: absolute; }
  .dd-loading__spinner {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translateX(-50%); }
    .dd-loading__content-wrapper .dd-loading__spinner {
      top: 50%;
      left: 50%; }
    .dd-loading__spinner svg {
      width: 100px;
      height: 100px; }
  .dd-loading__circles {
    animation-name: ddspinnerrotation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center center; }

@keyframes ddspinnerrotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.navbar {
  min-width: 320px; }
  .navbar.sticky-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1020; }
  @media (max-width: 47.99em) {
    .navbar__logo {
      height: 30px; } }

@media (min-width: 48em) {
  .dd-search__link--mobile {
    display: none; } }

@media (max-width: 47.99em) {
  .dd-search__link--desktop {
    display: none; } }

.dd-page-container {
  display: flex;
  flex-direction: row; }

.dd-page-sidebar {
  flex-flow: start;
  align-self: stretch;
  min-height: 100vh; }
  .dd-page-sidebar a {
    color: #fff; }

.dd-page-content {
  align-self: stretch;
  width: 100%;
  min-height: 100vh; }
  @media (min-width: 36em) {
    .dd-page-content {
      width: calc(100vw - 56px); } }
  @media (min-width: 48em) {
    .dd-page-content {
      width: calc(100vw - 250px - 56px); } }
  .dd-page-content .content-section {
    padding: 6rem 2rem 4rem; }
    @media (max-width: 35.99em) {
      .dd-page-content .content-section {
        padding-right: 0rem;
        padding-left: 0rem; } }
    @media (min-width: 75em) {
      .dd-page-content .content-section {
        padding-top: 6rem;
        padding-bottom: 6rem; } }

#sidebar {
  width: 250px;
  height: 100%;
  z-index: 999;
  background: #0064b3;
  color: #fff;
  transition: all 0.3s; }

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc; }

#sidebar ul.components {
  padding: 40px 0; }

#sidebar ul p {
  color: #fff;
  padding: 10px; }

#sidebar ul li a {
  padding: 2px;
  font-size: 1.1em;
  display: block; }
  #sidebar ul li a.active {
    color: #04bbee !important; }

#sidebar ul li > a {
  font-family: "EuclidSquare Regular", sans-serif;
  font-weight: 600;
  font-style: normal; }

#sidebar ul li a:hover {
  text-decoration: none; }

#sidebar ul li.active > a, a[aria-expanded="true"] {
  color: #fff;
  background: #0064b3; }

ul ul a {
  font-size: 0.9em;
  padding-left: 30px;
  background: #6d7fcc; }

a[data-toggle="collapse"] {
  position: relative; }

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%); }

#sidebarCollapse {
  width: 40px;
  height: 40px;
  border-style: none;
  cursor: pointer; }

#sidebarCollapse span {
  width: 60%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #fff;
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375); }

#sidebarCollapse span:first-of-type {
  transform: rotate(45deg) translate(2px, 2px); }

#sidebarCollapse span:nth-of-type(2) {
  opacity: 0; }

#sidebarCollapse span:last-of-type {
  transform: rotate(-45deg) translate(1px, -1px); }

#sidebarCollapse.active span {
  transform: none;
  opacity: 1;
  margin: 3px auto;
  background: #fff; }

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
    transform: rotateY(90deg); }
  #sidebar.active {
    margin-left: 0;
    transform: none; }
  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    transform: none;
    opacity: 1;
    margin: 5px auto; }
  #sidebarCollapse.active span {
    margin: 0 auto; }
  #sidebarCollapse.active span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px); }
  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0; }
  #sidebarCollapse.active span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px); } }

.search-form {
  min-width: 150px;
  width: 30vw;
  max-width: 100%; }

.search-form .input-group {
  transition: all 0.35s, border-radius 0s;
  height: 32px;
  margin-top: -5px; }
  .search-form .input-group input.form-control {
    padding-right: 20px;
    background: transparent;
    box-shadow: none;
    display: block;
    background-color: #fff;
    border: 1px solid #ccc; }
  .search-form .input-groupinput.form-control, .search-form .input-groupinput.form-control {
    width: 520px; }
  .search-form .input-group i.form-control-feedback {
    position: absolute;
    top: 2px;
    right: 1px;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #a2aaad;
    left: initial;
    font-size: 14px;
    background: white; }

.search-form .input-group input.form-control:focus::-webkit-input-placeholder {
  display: none;
  color: transparent; }

.search-form .input-group input.form-control:focus:-moz-placeholder {
  /* Firefox 18- */
  display: none;
  color: transparent; }

.search-form .input-group input.form-control:focus::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
  color: transparent; }

.search-form .input-group input.form-control:focus:-ms-input-placeholder {
  display: none;
  color: transparent; }

.dd-vertical-divider {
  border-right: 1px solid #034067;
  height: 100%;
  margin-right: 50%; }

.dd-subsite-button-wrap {
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 5vh;
  color: white;
  z-index: 1019;
  background-color: #04bbee; }
  @media (min-width: 48em) {
    .dd-subsite-button-wrap {
      z-index: 1017;
      top: 0;
      right: -1px;
      bottom: 0;
      height: 100vh;
      width: 56px; } }
  .dd-subsite-button-wrap:hover {
    background-color: #0064b3; }
  .dd-subsite-button-wrap .dd-subsite-button {
    font-family: "EuclidSquare Regular", sans-serif;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;
    position: relative;
    top: 50%;
    font-size: 18px;
    padding: 5px 12px; }
    @media (min-width: 48em) {
      .dd-subsite-button-wrap .dd-subsite-button {
        transform: rotate(-90deg) translateX(100%);
        width: 124px;
        left: -65%;
        padding: 0; } }

.dd-subsite-content {
  background-color: #0064b3;
  color: white;
  width: 100vw;
  z-index: 1001;
  transition: 0.3s; }
  .dd-subsite-content .dd-intro__content {
    color: white; }

.dd-subsite-content {
  background-color: #0064b3;
  color: white; }

.dd-scripted-overlay {
  will-change: width, height, left, top;
  position: relative;
  z-index: 1001; }

@media print {
  .dd-no-print {
    display: none !important; } }

body {
  background: #fff;
  min-width: 320px;
  scroll-behavior: smooth; }

html,
body,
main {
  min-height: 100vh;
  font-family: "EuclidSquare Regular", Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dd-subsite-content .dd-page-content {
  width: 100%; }
  @media (max-width: 35.99em) {
    .dd-subsite-content .dd-page-content {
      width: calc(100vw - 56px);
      margin-left: 56px; } }
  .dd-subsite-content .dd-page-content a {
    color: white;
    font-family: "EuclidSquare Semibold"; }

.dd-pagination .icon {
  width: 20px;
  height: 20px; }

.dd-list {
  column-count: 1; }

.dd-list__wrapper h3 {
  width: 100%; }

a.dd-feature {
  width: 100%; }

.dd-scripted-overlay-hide {
  opacity: 0;
  display: none; }

.dd-scripted-overlay-show {
  opacity: 1;
  display: block; }

.transition {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

hr {
  border-color: white; }

.dd-intro__content {
  color: #0064b3; }

.dd-img-vertical {
  height: 100%;
  object-fit: cover;
  object-position: right; }
  @media (max-width: 61.99em) {
    .dd-img-vertical {
      width: 100%;
      height: auto; } }

.dd-img-horizontal {
  width: 100%;
  height: auto; }

.dd-table {
  border-top: 3px solid #0064b3;
  margin-bottom: 50px;
  line-height: 1.5rem; }
  .dd-table thead > tr > th {
    border-bottom: 3px solid #0064b3;
    font-family: "EuclidSquare Semibold"; }
  .dd-table tbody > tr > th {
    font-family: "EuclidSquare Regular";
    font-weight: normal; }
  .dd-table td, .dd-table th {
    padding-left: 0; }

table.dd-table__separated {
  border: none; }
  table.dd-table__separated thead > tr > th {
    min-width: 60px;
    border-right: 10px solid white; }
    table.dd-table__separated thead > tr > th:last-child {
      border-right: none; }
  table.dd-table__separated tbody {
    border: none; }
    table.dd-table__separated tbody tr > td, table.dd-table__separated tbody th {
      border-right: 10px solid white; }
      table.dd-table__separated tbody tr > td:last-child, table.dd-table__separated tbody th:last-child {
        border-right: none; }

table.dd-table__no-outline {
  border: unset; }
  table.dd-table__no-outline tbody {
    border-bottom: 1px solid #0064b3; }
    table.dd-table__no-outline tbody tr:last-child {
      border-bottom: 0px solid white; }

table.dd-table__no-highlights th[scope="row"] {
  font-weight: normal; }

table.dd-table {
  margin-bottom: unset; }
  table.dd-table tbody td:hover {
    padding-left: 0;
    color: unset; }

nav#sidebar {
  margin-left: 0px;
  transform: rotateY(0deg); }
  nav#sidebar a.active {
    color: white;
    font-weight: bold; }
  nav#sidebar a:hover, nav#sidebar a.active:hover {
    color: #04bbee; }
  nav#sidebar.is-active {
    margin-left: 0px;
    transform: rotateY(0deg);
    left: 0%; }
  @media (max-width: 47.99em) {
    nav#sidebar {
      position: fixed;
      left: -100%;
      top: 0;
      width: 100%;
      overflow-y: scroll;
      z-index: 1019; } }

nav#frontnavigation a, nav#frontnavigation a.nav-link.active, nav#frontnavigation a.nav-item {
  color: #a2aaad; }
  nav#frontnavigation a:hover, nav#frontnavigation a.nav-link.active:hover, nav#frontnavigation a.nav-item:hover {
    color: #04bbee; }

nav#frontnavigation a[aria-expanded=true] {
  background: rgba(0, 0, 0, 0.05);
  color: #04bbee; }

nav#frontnavigation ul.navbar-nav {
  padding-top: 9px; }
  @media (max-width: 47.99em) {
    nav#frontnavigation ul.navbar-nav {
      padding-right: 10px; } }

.dd-intro__text.lead {
  font-family: "EuclidSquare Semibold"; }

#navbar-mobile-trigger {
  width: 25px;
  height: 20px;
  border-top: 2px #a2aaad solid;
  border-bottom: 2px #a2aaad solid;
  display: none;
  position: relative;
  right: -10px;
  top: 2px;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }
  #navbar-mobile-trigger:hover {
    border-color: #04bbee; }
  #navbar-mobile-trigger:before, #navbar-mobile-trigger:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 7px;
    background: #a2aaad;
    display: block;
    transition: all 0.3s ease-in-out; }
  @media (max-width: 47.99em) {
    #navbar-mobile-trigger {
      display: block; } }
  @media (max-width: 35.99em) {
    #navbar-mobile-trigger {
      margin-right: 10px; } }
  #navbar-mobile-trigger:hover:before, #navbar-mobile-trigger:hover:after {
    background-color: #04bbee; }

.text-black {
  color: black !important; }

.dd-video__icon {
  opacity: .9; }
  .dd-video__icon:hover {
    opacity: 1;
    cursor: pointer; }

.dd-overlaypage-content .content-section {
  margin-left: 3rem; }

.dd-overlaypage-content .dd-feature__title {
  font-size: 48px;
  min-width: 50%;
  max-width: 380px; }

.font-icon-detail span {
  padding: 5px; }
  .font-icon-detail span svg {
    fill: #a2aaad; }
  .font-icon-detail span:hover svg, .font-icon-detail span:focus svg {
    fill: #04bbee;
    cursor: pointer; }

.dd-auth-form h3.display-3 {
  font-size: 32px;
  margin-bottom: 64px; }

.dd-auth-form button.btn {
  min-width: 200px;
  text-align: center;
  padding: 15px 20px;
  margin-bottom: 1rem; }

@media (max-width: 35.99em) {
  .dd-auth-form__buttons a,
  .dd-auth-form__buttons .btn {
    display: block;
    width: 100%; } }

.dd-auth-form a {
  color: white; }
  .dd-auth-form a:hover {
    text-decoration: none;
    color: #04bbee; }

.dd-auth-form p {
  font-size: 14px; }

.dd-auth-form .form-control {
  border-radius: 0;
  min-height: 50px;
  background: white;
  margin-bottom: 20px; }
  .dd-auth-form .form-control::placeholder {
    color: #a2aaad; }

.dd-auth-form .form-group {
  padding-right: 20px; }

.dd-grid {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  line-height: 0;
  box-sizing: border-box; }

.dd-grid__item {
  position: relative;
  width: 100%; }
  .dd-grid__item:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3; }
  .dd-grid__item:nth-child(2) {
    grid-column: 2 / 3; }
  .dd-grid__item:nth-child(3) {
    grid-column: 2 / 3; }
  .dd-grid__item:before {
    content: "";
    display: inline-block;
    padding-top: 100%;
    position: relative; }
  .dd-grid__item .dd-grid__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: grid; }
  .dd-grid__item .dd-grid__content-inside img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.search-form {
  min-width: 0px; }
  .search-form .form-control {
    border: 0px;
    display: none; }

.dd-best-practices {
  color: white;
  float: right; }

@media (max-width: 47.99em) {
  .dd-block--images div {
    margin-bottom: 24px; } }

.card-title {
  font-family: "EuclidSquare Semibold"; }

img {
  pointer-events: none; }
