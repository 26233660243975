// Nutzt den Ratio Buddy Hack: https://ratiobuddy.com/ für Aspect-Ratio
.dd-hero {
  position: relative;
  color: $white;
  max-height: 100vh;
  height: calc(100vh - 77px);


  @include mq($until: lg) {
    height: unset;
    max-height: unset;
    margin-top: 51px;
    min-height: unset; }

  &:before {
    @include mq($until: lg) {
      content: '';
      padding-top: (9 / 16) * 100%;
      width: 100%;
      display: block; } }

  .overlay {
    opacity: 0.2; }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    video {
      @include mq($until: lg) {
        top: 0;
        left: 0;
        min-width: unset;
        min-height: unset;
        height: auto;
        width: 100%;
        transform: none;
        -webkit-transform: none; } } }

  &__container {
    max-width: calc(100vw - 57px);
    display: inline-block;
    vertical-align: bottom;
    display: flex;
    align-items: center;

    @include mq($until: lg) {
      top: 0;
      left: 0;
      min-width: unset;
      min-height: unset;
      height: auto;
      width: 100%;
      transform: none;
      -webkit-transform: none; } }

  &__content {
    padding: 20px 10px;
    color: $white;
    max-width: 520px; }

  &__title {
    font-size: 60px;
    line-height: 1.23;

    @include mq($until: md) {
      font-size: 48px; }

    @include mq($until: sm) {
      font-size: 36px; }

    @include mq($until: xs) {
      font-size: 32px; } }

  &__arrow {
    position: relative;
    bottom: 100px;
    width: 100%;
    display: block;
    z-index: 1;

    @include mq($until: md) {
      display: none; }

    &:hover {
      text-decoration: none; }

    &:after {
      content: '';
      margin: auto;
      width: 30px;
      height: 30px;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      position: absolute;
      transform: rotate(45deg);
      display: block;
      bottom: -35px;
      z-index: 3;
      left: calc(50% - 15px); }

    span {
      display: block;
      text-align: center;
      width: 100%;
      color: $white;
      line-height: 33px;
      font-size: 22px;
      @include f-s; } } }
