.mb-6 {
  margin-bottom: 4rem !important;
}
.mb-7 {
  margin-bottom: 5rem !important;
}
.mb-8 {
  margin-bottom: 6rem !important;
}
.mb-9 {
  margin-bottom: 7rem !important;
}
.mb-10 {
  margin-bottom: 8rem !important;
}

// Margin oben und unten
.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
.my-9 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}
.my-10 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

// Paddings
// Oben und unten

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.py-9 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}
.py-10 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}
