.dd-image-card {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0; }

  &:hover::after {
    opacity: .5; }


  &__image {
    transition: transform .3s ease-in-out;

    .dd-image-card:hover & {
      transform: scale(1.05); } }

  &__actions {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s ease-in-out;


    .dd-image-card:hover & {
      opacity: 1; } } }
