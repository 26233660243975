.dd-social-icon {

  .icon {
    width: 48px;
    height: 48px;
    background-color: $primary;
    padding: 10px;
    border-radius: 50%;
    transition: all $trans ease-in-out;

    &:hover,
    &:focus {
      background-color: $secondary; }

    svg {
      use {
        fill: #fff; } } } }
