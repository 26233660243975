.dd-iconlist {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .row {
    margin-left: 0; }

  &__headline {
    @include t-h2;
    color: $primary;
    margin-bottom: 3rem; }

  &__item {
    padding-left: 0;
    margin-bottom: 3rem;
    display: flex;

    &-icon-container {
      padding-left: 0; } }

  &__icon {
    display: inline-block;

    .icon {
      height: 50px;
      width: 50px;

      svg use {
        fill: $primary; } } }

  &__text {
    padding-left: 0.5rem;

    @include mq($from: md) {
      padding-left: 0; }


    &-title {
      @include t-h4;
      color: $primary; } } }
