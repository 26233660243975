body {
  position: relative; // @see bootstrap scrollspy
  min-width: 320px; }

img {
  max-width: 100%; }

.reveal {}
//   opacity: 0
//   transition: all 0.8s ease-in-out
//   transform: translateY(20px)

//&.is-active
//  opacity: 1
//  transform: translateY(0px)
