// move rot in an extra div
.dd-subsite-button-wrap {
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 5vh;
  color: white;
  z-index: 1019;
  background-color: $cyan;

  &.is-active {}

  @include mq($from: md) {
    z-index: 1017;
    top: 0;
    right: -1px;
    bottom: 0;
    height: 100vh;
    width: 56px; }


  &:hover {
    background-color: $roe-blue;
    // border-right: 1px solid $cyan

    // .dd-subsite-button
 }    //   color: $secondary

  .dd-subsite-button {
    @include f-s;
    cursor: pointer;
    position: relative;
    top: 50%;
    font-size: 18px;
    padding: 5px 12px;

    @include mq($from: md) {
      transform: rotate(-90deg) translateX(100%);
      width: 124px;
      left: -65%;
      padding: 0; } } }


.dd-subsite-content {
  background-color: $roe-blue;
  color: white;
  width: 100vw;
  z-index: 1001;
  transition: 0.3s;

  .dd-intro__content {
    color: white; } }

.dd-subsite-content {
  background-color: $roe-blue;
  color: white; }


.dd-scripted-overlay {
  will-change: width, height, left, top;
  position: relative;
  z-index: 1001; }

