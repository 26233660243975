.dd-loading {
  transition: all .5s;

  &__content-wrapper {
    position: relative; }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    z-index: 9999;

    .dd-loading__content-wrapper & {
      position: absolute; } }

  &__spinner {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translateX(-50%);

    .dd-loading__content-wrapper & {
      top: 50%;
      left: 50%; }

    svg {
      width: 100px;
      height: 100px; } }

  &__circles {
    animation-name: ddspinnerrotation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center center; } }

@keyframes ddspinnerrotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

