.dd-video {
  position: relative;
  padding-top: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }

  &__caption {
    padding-top: 24px;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%); } }
