// Variables

$trans: .3s;
$dd-spacing: 80px;
$dd-spacing-mobile: 40px;

$dd-header-height: 68px;

$dd-mobile-navi: 1023px;
$dd-desktop-navi: 1024px; // mq bug
