@mixin t-h1 {
  @include f-b;
  font-size: $fs-h1;
  line-height: 1.5;

  @include mq($until: sm) {
    font-size: $fs-h2; } }

@mixin t-h2 {
  @include f-b;
  font-size: $fs-h2;
  line-height: 1.5;
  padding-bottom: 10px;

  @include mq($until: sm) {
    font-size: $fs-h3; } }

@mixin t-h3 {
  @include f-b;
  font-size: $fs-h3;
  line-height: 1.5;
  color: $blue;

  @include mq($until: sm) {
    font-size: $font-size-lg; } }

@mixin t-h4 {
  @include f-l;
  font-size: $fs-h4;
  line-height: 1.5;

  @include mq($until: sm) {
    font-size: $font-size-base; } }

@mixin t-h5 {
  @include f-s;
  font-size: $fs-h5;
  line-height: 1.5;

  @include mq($until: sm) {
    font-size: $font-size-base; } }

@mixin t-h6 {
  @include f-s;
  font-size: $fs-h6;
  line-height: 1.5;

  @include mq($until: sm) {
    font-size: $font-size-base; } }


h1, .h1 {
  @include t-h1; }

h2, .h2 {
  @include t-h2; }

h3, .h3 {
  @include t-h3; }

h4, .h4 {
  @include t-h4;

  .dd-block--text & {
    padding-bottom: 1.1rem; } }


h5, .h5 {
  @include t-h5; }

h6, .h6 {
  @include t-h6; }


.text-gray {
  &-200 {
    color: #e9e9e9; }
  &-400 {
    color: #a2aaad; }
  &-600 {
    color: #4e575e; }
  &-800 {
    color: #253746; } }

.text-blue {
  color: $blue; }

//   p, ul, ol, dl, figure
//     margin-bottom: 1.2em
//     font-size: 16px

//   li
//     margin-left: 2em

//   ul li
//     list-style-type: disc

//   img
//     max-width: 100%

//   a
//     color: $c-primary
//     text-decoration: none

//     &:hover
//       text-decoration: underline

//   strong
//     font-weight: 500

// //   em
// //     font-style: italic

