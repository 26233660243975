.dd-table {
  @include f-r;
  font-size: 16px;
  line-height: 34px;

  th.col-75 {
    width: 75%; }
  th.col-50 {
    width: 50%; }

  a {
    font-weight: 500;

    &:hover {
      text-decoration: none; } }

  th, tr, td, thead th {
    border-color: $primary; }

  thead th {
    border-top: none;
    border-bottom: 3px solid $primary;
    font-weight: bold;
    color: $primary;
    font-size: 16px; }

  tbody {
    border-bottom: 3px solid $primary;

    td {
      transition: padding $trans ease-in-out;

      &:hover {
        padding-left: 20px;
        color: $primary; } } } }

.dd-tablefilter {
  padding: 20px;

  .dd-tablefilter--viewmode {
    .dd-icon-view {
      color: white;

      .icon {
        height: 30px;
        width: 30px;

        svg use {
          fill: $white; } } }

    ul {
      padding: 0;
      display: inline-flex;
      list-style: none; }

    li {
      margin-right: 1.5rem;

      a {
        display: flex;
        color: white;

        .dd-icon-view {
          margin-right: 10px; }

        &:hover {
          cursor: pointer;
          color: $secondary;
          text-decoration: none;

          svg use, svg:hover use {
            fill: $secondary; } } } } }

  input, select, .form-control {
    border-radius: 0;

    &::placeholder {
      color: $gray-400; } }

  .dd-tablefilter-button-wrapper {
    text-align: right; } }

